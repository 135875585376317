<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title" translate>
    @if(editContact){
        <span translate>EDIT_CONTACT</span>
    }@else{
        <span translate>CREATE_CONTACT</span>
    }
    
    </h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body" [formGroup]="contactForm">
  <lib-gung-text-input [gungTranslate]="'FIRST_NAME'" formControlName="firstname"> </lib-gung-text-input>
  <lib-forms-message-error [controller]="contactForm.get('firstname')"></lib-forms-message-error>

  <lib-gung-text-input [gungTranslate]="'LAST_NAME'" formControlName="lastname"> </lib-gung-text-input>
  <lib-forms-message-error [controller]="contactForm.get('lastname')"></lib-forms-message-error>

  <lib-gung-text-input [gungTranslate]="'EMAIL'" formControlName="email"> </lib-gung-text-input>
  <lib-forms-message-error [controller]="contactForm.get('email')"></lib-forms-message-error>

  <lib-gung-text-input [gungTranslate]="'PHONE'" formControlName="phone"> </lib-gung-text-input>
  <lib-forms-message-error [controller]="contactForm.get('phone')"></lib-forms-message-error>
</div>
<div class="modal-footer">
  <div class="row w-100">
    <div class="col-12">
      <div class="btn-group btn-group-justified w-100">
        <button class="btn btn-sm btn-outline-secondary btn-default" (click)="activeModal.dismiss()" translate>
          CANCEL
        </button>
        <button class="btn btn-sm btn-primary btn-default" (click)="save($event)" translate>SAVE</button>
      </div>
    </div>
  </div>
</div>
