import { Injectable } from '@angular/core';

import { AuthService } from '../auth/auth.service';
import { SelectedCustomerService } from '../selected-customer/selected-customer.service';
import { combineLatest, debounceTime, first, map, Observable } from 'rxjs';
import { Customer } from '../../models/customer';
import { User } from '../../state/auth/types';
import { FeatureActivatedGuardWrapper } from '../../guards/feature-activated/feature-activated.guard';
import { BackendFeatureService, GungFeatureMap } from '../backend-feature.service';
import {
  ROLE_ACTUATOR,
  ROLE_ADMIN,
  ROLE_ANONYMOUS,
  ROLE_BUYER,
  ROLE_SALES,
  ROLE_SUPPLIER,
  ROLE_USER
} from '../../models';
import { ConfigurationsService } from '../configurations.service';

export interface GungMenu {
  heading: string;
  link?: string;
  links?: GungSimpleMenu[];
  icon: string;
  open?: boolean;
  enabled?(feature: GungFeatureMap, roles: Set<String>): boolean;
  action?(event?): void;
}

export interface GungSimpleMenu {
  heading: string;
  link?: string;
  icon: string;
  enabled?(feature: GungFeatureMap, roles: Set<String>): boolean;
  action?(event?): void;
}

export interface GungMenuItem {
  /**
   * The unique ID of the menu item. This is what is used to match with existing standard
   * menu items and replace values.
   */
  id: string;

  /**
   * The menu item translation tag.
   */
  title: string;

  /**
   * The path to the page. Should not include any domain names. For example: "/customers", "/products".
   */
  pagePath: string;

  /**
   * The menu group that this menu item belongs to.
   */
  groupId: string;

  /**
   * The CSS classes used to display the icon. For example: "fa fa-user", "fa fa-cogs".
   */
  iconCss: string;

  /**
   * The feature ID that this menu item is dependent on. If the feature is not enabled, the menu item is not visible.
   */
  dependantOnFeatureId: string;

  /**
   * The roles that this menu item is visible for. If the role is not present in the map, it is not visible.
   */
  visibleForRoles?: { [role: string]: boolean };

  /**
   * The order of the menu item in the menu. Higher numbers are displayed first. We require a boxed value here in order
   * to detect when a value has been explicitly set or not. Null value means that it has not been set. A null value is
   * treated as the lowest priority.
   */
  sortingPriority?: number;
}

export interface GungMenuConfig {
  menuItems: { [k: string]: GungMenuItem };
}

@Injectable({
  providedIn: 'root'
})
export class GungMainMenuService {
  constructor(
    protected authService: AuthService,
    protected selectedCustomerService: SelectedCustomerService,
    protected backendFeatureService: BackendFeatureService,
    protected featureActivatedGuardWrapper: FeatureActivatedGuardWrapper,
    protected configurationService: ConfigurationsService
  ) {}

  /**
   * Get menu for main menu
   * @returns Menu as Observable
   */
  getMenu(): Observable<GungMenu[]> {
    return combineLatest({
      user: this.authService.getCurrentUser(),
      selectedCustomer: this.selectedCustomerService.getSelectedCustomer(),
      roles: this.authService.getRoles(),
      features: this.backendFeatureService.getAvailableFeatures(),
      menuConfig: this.configurationService
        .getConfigurationsByIds(['MenuConfiguration'])
        .pipe(map(config => (config && config.length > 0 ? config[0] : null)))
    }).pipe(
      debounceTime(500),
      map(({ user, selectedCustomer, roles, features, menuConfig }) => {
        return this.mountMenu(user, roles, selectedCustomer, features, menuConfig);
      })
    );
  }

  mountMenu(
    user: User,
    roles: string[],
    selectedCustomer: Customer,
    features: GungFeatureMap,
    menuConfig: GungMenuConfig
  ): GungMenu[] {
    let mainMenu = this.getMenuGroupsWithLinks(menuConfig);

    const userRoles = new Set(roles);
    // Here we filter out the menus that the user does not have access to
    // based on the roles of the user. If a menu has no allowed roles, it is
    // shown. If a link has no allowed roles, it is shown. If a menu has no links
    // and no action, it is not shown.
    mainMenu = mainMenu.filter(menu => {
      menu.links = (menu.links || []).filter(link => {
        // If the link has no allowed roles, show it. Default show for all.
        if (!link.enabled) {
          return true;
        }

        return link.enabled(features, userRoles);
      });

      if (menu.links.length == 0 && !menu.action) {
        return false;
      }

      // If the menu has no allowed roles, show it. Default show for all.
      if (!menu.enabled) {
        return true;
      }
      return menu.enabled(features, userRoles);
    });

    return mainMenu;
  }

  getMenuGroupsWithLinks(menuConfig: GungMenuConfig): GungMenu[] {
    const groupLinks: { [groupId: string]: GungMenuItem[] } = this.getConfiguredMenuItems(menuConfig).reduce(
      (acc, item) => {
        acc[item.groupId] = [...(acc[item.groupId] || []), item];
        return acc;
      },
      {}
    );

    return this.getStandardMenuGroupsWithoutLinks().map(group => {
      return {
        ...group,
        links: this.mapToGungMenu(groupLinks[group.heading] || [])
      };
    });
  }

  mapToGungMenu(menuItems: GungMenuItem[]): GungSimpleMenu[] {
    return (
      menuItems
        // Sort the menu items by sorting priority. Higher numbers should be displayed first.
        .sort((a, b) => b.sortingPriority - a.sortingPriority)
        .map(item => {
          return {
            heading: item.title,
            link: item.pagePath,
            icon: item.iconCss,
            enabled: (features: GungFeatureMap, roles: Set<String>): boolean => {
              const roleIsAllowed = this.rolesIsAllowed(item, roles);
              if (!roleIsAllowed) {
                return false;
              }

              if (!item.dependantOnFeatureId) {
                return true;
              } else {
                return this.featureActivatedGuardWrapper.isActivatedInstant(
                  features,
                  item.dependantOnFeatureId,
                  '3.0.15', // We need to sort this out later, but for now we just need to make it work
                  true
                );
              }
            }
          };
        })
    );
  }

  rolesIsAllowed(item: GungMenuItem, roles: Set<String>): boolean {
    /*
    Since our roles are "inherited" it is not as easy as just checking if the role is present in the map.
    For example, a salesperson will have roles "USER" + "SALES". When we configure the menu items, we check on the highest
    level of access. I.e. checking / allowing "USER" but not "SALES" means that the salesperson should not see the menu item.
    It is therefore not enough to just check if the role is present in the map, we need to check at the highest level.
     */
    for (const role of Object.keys(item.visibleForRoles)) {
      // A role configured does not exist on our current user. We can automatically skip this role.
      if (!item.visibleForRoles[role]) {
        continue;
      }

      if (ROLE_USER === role) {
        const isUser = roles.has(ROLE_USER) && !roles.has(ROLE_SALES) && !roles.has(ROLE_ADMIN);
        if (isUser) {
          return true;
        }
      } else if (ROLE_SALES === role) {
        const isSales = roles.has(ROLE_USER) && roles.has(ROLE_SALES) && !roles.has(ROLE_ADMIN);
        if (isSales) {
          return true;
        }
      } else if (ROLE_ADMIN === role) {
        const isAdmin = roles.has(ROLE_USER) && roles.has(ROLE_SALES) && roles.has(ROLE_ADMIN);
        if (isAdmin) {
          return true;
        }
      } else if (ROLE_SUPPLIER === role) {
        const isSupplier = roles.has(ROLE_SUPPLIER) && !roles.has(ROLE_BUYER);
        if (isSupplier) {
          return true;
        }
      } else if (ROLE_BUYER === role) {
        const isBuyer = roles.has(ROLE_BUYER) && !roles.has(ROLE_SUPPLIER);
        if (isBuyer) {
          return true;
        }
      } else if (ROLE_ANONYMOUS === role) {
        const isAnonymous = roles.has(ROLE_ANONYMOUS);
        if (isAnonymous) {
          return true;
        }
      } else if (ROLE_ACTUATOR === role) {
        const isActuator = roles.has(ROLE_ACTUATOR);
        if (isActuator) {
          return true;
        }
      } else {
        // All other roles does not have any "relational" aspect to other roles. Here we can do 1 to 1 mapping of roles.
        if (roles.has(role)) {
          return true;
        }
      }
    }

    return false;
  }

  getStandardMenuGroupsWithoutLinks(): GungMenu[] {
    return [
      {
        heading: 'MY_PAGES',
        icon: 'fa-solid fa-user',
        open: false
      },
      {
        heading: 'EXPORT_IMPORT',
        icon: 'fa-solid fa-file-export',
        open: false
      },
      {
        heading: 'TOOLS',
        icon: 'fa-solid fa-screwdriver-wrench',
        open: false
      },
      {
        heading: 'ADMIN',
        icon: 'fa-solid fa-user-tie',
        open: false
      },
      {
        heading: 'SYSTEM',
        icon: 'fa-solid fa-gear',
        open: false
      }
    ];
  }

  /**
   * Merges the standard menus with the menu configurations made by the B2B admin.
   * @param menuConfig The menu configuration.
   */
  getConfiguredMenuItems(menuConfig: GungMenuConfig): GungMenuItem[] {
    const standardMenuItems = this.getStandardMenuItems();
    if (!menuConfig) {
      return standardMenuItems;
    }

    const seenIds = new Set<String>();
    const toReturn = [];
    for (const item of standardMenuItems) {
      seenIds.add(item.id);
      // If the menu item has been configured we want to add the configured values to the standard values. If we have not
      // made any configurations for the menu item, we want to keep the standard values.
      if (menuConfig.menuItems[item.id]) {
        // We only want to replace the values that are not null and keep the standard values for the rest.
        toReturn.push({
          ...item,
          ...Object.fromEntries(Object.entries(menuConfig.menuItems[item.id]).filter(([_, v]) => v != null)),
          // Ensure we properly merge inside the visible for roles object, since that will always exist on config
          // items. We only care about the keys inside. If we don't do this, we will overwrite standard values with empty
          // object if we have not made any access rights changes in the config.
          visibleForRoles: {
            ...item.visibleForRoles,
            ...menuConfig.menuItems[item.id].visibleForRoles
          }
        });
      } else {
        toReturn.push(item);
      }
    }

    // If we have any custom menu items that are not standard, we want to add them to the list.
    for (const id of Object.keys(menuConfig.menuItems)) {
      if (!seenIds.has(id)) {
        toReturn.push({
          ...menuConfig.menuItems[id]
        });
      }
    }

    return toReturn;
  }

  getStandardMenuItems(): GungMenuItem[] {
    return [
      {
        id: 'STANDARD_MY_ACCOUNT',
        title: 'MY_ACCOUNT',
        pagePath: '/selected-customer',
        groupId: 'MY_PAGES',
        iconCss: 'fa-regular fa-user',
        dependantOnFeatureId: null,
        sortingPriority: 36000,
        visibleForRoles: {
          USER: true
        }
      },
      {
        id: 'STANDARD_MY_CUSTOMERS',
        title: 'MY_CUSTOMERS',
        pagePath: '/customers',
        groupId: 'MY_PAGES',
        iconCss: 'fa-regular fa-users',
        dependantOnFeatureId: null,
        sortingPriority: 35000,
        visibleForRoles: {
          SALES: true,
          ADMIN: true
        }
      },
      {
        id: 'STANDARD_OFFERS',
        title: 'OFFERS',
        pagePath: '/offers',
        groupId: 'MY_PAGES',
        iconCss: 'fa-regular fa-briefcase',
        dependantOnFeatureId: null,
        sortingPriority: 34000,
        visibleForRoles: {
          USER: true,
          SALES: true,
          ADMIN: true
        }
      },
      {
        id: 'STANDARD_ORDERS',
        title: 'ORDERS',
        pagePath: '/open-orders',
        groupId: 'MY_PAGES',
        iconCss: 'fa-solid fa-briefcase',
        dependantOnFeatureId: null,
        sortingPriority: 33000,
        visibleForRoles: {
          USER: true,
          SALES: true,
          ADMIN: true
        }
      },
      {
        id: 'STANDARD_QUICK_ORDER',
        title: 'QUICK_ORDER',
        pagePath: '/quickorder',
        groupId: 'MY_PAGES',
        iconCss: 'fa-solid fa-cart-shopping-fast',
        dependantOnFeatureId: null,
        sortingPriority: 32000,
        visibleForRoles: {
          USER: true,
          SALES: true,
          ADMIN: true
        }
      },
      {
        id: 'STANDARD_BULK_ORDERS',
        title: 'BULK_ORDERS',
        pagePath: '/bulk-order',
        groupId: 'MY_PAGES',
        iconCss: 'fa-solid fa-suitcase-rolling',
        dependantOnFeatureId: null,
        sortingPriority: 31000,
        visibleForRoles: {
          USER: true,
          SALES: true,
          ADMIN: true
        }
      },
      {
        id: 'STANDARD_INVOICES',
        title: 'INVOICES',
        pagePath: '/invoices',
        groupId: 'MY_PAGES',
        iconCss: 'fa-solid fa-file-invoice-dollar',
        dependantOnFeatureId: null,
        sortingPriority: 30000,
        visibleForRoles: {
          USER: true,
          SALES: true,
          ADMIN: true
        }
      },
      {
        id: 'STANDARD_USER_RETURN_WARRANTY',
        title: 'RETURN_WARRANTY',
        pagePath: '/return-warranty',
        groupId: 'MY_PAGES',
        iconCss: 'fa-solid fa-shield-check',
        dependantOnFeatureId: 'gung-warranty-claims',
        sortingPriority: 29000,
        visibleForRoles: {
          USER: true
        }
      },
      {
        id: 'STANDARD_SALES_RETURN_WARRANTY',
        title: 'RETURN_WARRANTY',
        pagePath: '/return-warranty',
        groupId: 'MY_PAGES',
        iconCss: 'fa-solid fa-shield-check',
        dependantOnFeatureId: null,
        sortingPriority: 28000,
        visibleForRoles: {
          SALES: true,
          ADMIN: true
        }
      },
      {
        id: 'STANDARD_SAVED_CARTS',
        title: 'SAVED_CARTS',
        pagePath: '/carts',
        groupId: 'MY_PAGES',
        iconCss: 'fa-solid fa-cart-circle-plus',
        dependantOnFeatureId: null,
        sortingPriority: 27000,
        visibleForRoles: {
          USER: true,
          SALES: true,
          ADMIN: true
        }
      },
      {
        id: 'STANDARD_FAVOURITES',
        title: 'FAVOURITES',
        pagePath: '/favourites',
        groupId: 'MY_PAGES',
        iconCss: 'fa-solid fa-heart',
        dependantOnFeatureId: null,
        sortingPriority: 26000,
        visibleForRoles: {
          USER: true,
          SALES: true,
          ADMIN: true
        }
      },
      {
        id: 'STANDARD_NEWS',
        title: 'NEWS',
        pagePath: '/news',
        groupId: 'MY_PAGES',
        iconCss: 'fa-solid fa-newspaper',
        dependantOnFeatureId: null,
        sortingPriority: 25000,
        visibleForRoles: {
          USER: true,
          SALES: true,
          ADMIN: true
        }
      },
      {
        id: 'STANDARD_PRODUCT_EXPORT_NAV_BAR',
        title: 'PRODUCT_EXPORT_NAV_BAR',
        pagePath: '/products-export',
        groupId: 'EXPORT_IMPORT',
        iconCss: 'fa-solid fa-file-arrow-down',
        dependantOnFeatureId: null,
        sortingPriority: 24000,
        visibleForRoles: {
          USER: true,
          SALES: true,
          ADMIN: true
        }
      },
      {
        id: 'STANDARD_IMPORT_LINESHEET',
        title: 'IMPORT_LINESHEET',
        pagePath: '/admin/import-linesheet',
        groupId: 'EXPORT_IMPORT',
        iconCss: 'fa-regular fa-file-spreadsheet',
        dependantOnFeatureId: null,
        sortingPriority: 23000,
        visibleForRoles: {
          SALES: true,
          ADMIN: true
        }
      },
      {
        id: 'STANDARD_PRODUCT_CATALOGUES',
        title: 'PRODUCT_CATALOGUES',
        pagePath: '/export-pdf',
        groupId: 'EXPORT_IMPORT',
        iconCss: 'fa-solid fa-file-export',
        dependantOnFeatureId: null,
        sortingPriority: 22000,
        visibleForRoles: {
          SALES: true,
          ADMIN: true
        }
      },
      {
        id: 'STANDARD_DIGITAL_ASSETS_USER',
        title: 'DIGITAL_ASSETS',
        pagePath: '/digital-assets',
        groupId: 'EXPORT_IMPORT',
        iconCss: 'fa-solid fa-images',
        dependantOnFeatureId: 'gung-feature-digitalassets',
        sortingPriority: 21000,
        visibleForRoles: {
          USER: true
        }
      },
      {
        id: 'STANDARD_DIGITAL_ASSETS_SALES',
        title: 'DIGITAL_ASSETS',
        pagePath: '/digital-assets',
        groupId: 'EXPORT_IMPORT',
        iconCss: 'fa-solid fa-images',
        dependantOnFeatureId: null,
        sortingPriority: 20000,
        visibleForRoles: {
          SALES: true,
          ADMIN: true
        }
      },
      {
        id: 'STANDARD_REPORT_CENTRAL',
        title: 'REPORT_CENTRAL',
        pagePath: '/report-central',
        groupId: 'EXPORT_IMPORT',
        iconCss: 'fa-regular fa-file-chart-column',
        dependantOnFeatureId: null,
        sortingPriority: 19000,
        visibleForRoles: {
          USER: true,
          SALES: true,
          ADMIN: true
        }
      },
      {
        id: 'STANDARD_SALES_DASHBOARD',
        title: 'SALES_DASHBOARD',
        pagePath: '/sales-dashboard',
        groupId: 'TOOLS',
        iconCss: 'fa-solid fa-chart-line',
        dependantOnFeatureId: null,
        sortingPriority: 18000,
        visibleForRoles: {
          SALES: true,
          ADMIN: true
        }
      },
      {
        id: 'STANDARD_ACTIVITY_INTERNAL',
        title: 'ACTIVITIES',
        pagePath: '/activity-internal',
        groupId: 'TOOLS',
        iconCss: 'fa-solid fa-chart-network',
        dependantOnFeatureId: null,
        sortingPriority: 17000,
        visibleForRoles: {
          SALES: true,
          ADMIN: true
        }
      },
      {
        id: 'STANDARD_BARCODE_SCANNER',
        title: 'BARCODE_SCANNER',
        pagePath: '/barcode-scanner',
        groupId: 'TOOLS',
        iconCss: 'fa-solid fa-barcode-read',
        dependantOnFeatureId: null,
        sortingPriority: 16000,
        visibleForRoles: {
          SALES: true,
          ADMIN: true
        }
      },
      {
        id: 'STANDARD_REPLENISHMENT',
        title: 'REPLENISHMENT',
        pagePath: '/replenishment',
        groupId: 'TOOLS',
        iconCss: 'fa-solid fa-people-carry-box',
        dependantOnFeatureId: null,
        sortingPriority: 15000,
        visibleForRoles: {
          SALES: true,
          ADMIN: true
        }
      },
      {
        id: 'STANDARD_EDI',
        title: 'EDI',
        pagePath: '/admin/edi',
        groupId: 'TOOLS',
        iconCss: 'fa-solid fa-tower-cell',
        dependantOnFeatureId: null,
        sortingPriority: 14000,
        visibleForRoles: {
          ADMIN: true
        }
      },
      {
        id: 'STANDARD_ORDER_PORTAL',
        title: 'ORDER_PORTAL',
        pagePath: '/admin/order-portal',
        groupId: 'TOOLS',
        iconCss: 'fa-solid fa-person-to-portal',
        dependantOnFeatureId: null,
        sortingPriority: 13000,
        visibleForRoles: {
          ADMIN: true
        }
      },
      {
        id: 'STANDARD_SETTINGS',
        title: 'SETTINGS',
        pagePath: '/admin/settings',
        groupId: 'ADMIN',
        iconCss: 'fa-solid fa-gear',
        dependantOnFeatureId: null,
        sortingPriority: 12000,
        visibleForRoles: {
          ADMIN: true
        }
      },
      {
        id: 'STANDARD_USERS',
        title: 'USERS',
        pagePath: '/admin/manage-users',
        groupId: 'ADMIN',
        iconCss: 'fa-solid fa-users',
        dependantOnFeatureId: null,
        sortingPriority: 11000,
        visibleForRoles: {
          ADMIN: true
        }
      },
      {
        id: 'STANDARD_GUNG_FLOWS',
        title: 'GUNG_FLOWS',
        pagePath: '/admin/gung-flows',
        groupId: 'ADMIN',
        iconCss: 'fa-solid fa-arrow-progress',
        dependantOnFeatureId: null,
        sortingPriority: 10000,
        visibleForRoles: {
          ADMIN: true
        }
      },
      {
        id: 'STANDARD_DOCUMENTS_ARCHIVE',
        title: 'DOCUMENTS_ARCHIVE',
        pagePath: '/admin/documents',
        groupId: 'ADMIN',
        iconCss: 'fa-solid fa-folder-open',
        dependantOnFeatureId: null,
        sortingPriority: 9000,
        visibleForRoles: {
          ADMIN: true
        }
      },
      {
        id: 'STANDARD_EXPORT_TEMPLATES',
        title: 'EXPORT_TEMPLATES',
        pagePath: '/admin/document-templates/template-item-editor',
        groupId: 'ADMIN',
        iconCss: 'fa-solid fa-download',
        dependantOnFeatureId: null,
        sortingPriority: 8000,
        visibleForRoles: {
          ADMIN: true
        }
      },
      {
        id: 'STANDARD_DIGITAL_ASSETS_MANAGEMENT',
        title: 'DIGITAL_ASSETS_MANAGEMENT',
        pagePath: '/digital-assets/management',
        groupId: 'ADMIN',
        iconCss: 'fa-solid fa-images',
        dependantOnFeatureId: null,
        sortingPriority: 7000,
        visibleForRoles: {
          ADMIN: true
        }
      },
      {
        id: 'STANDARD_NEWS_EDITOR',
        title: 'NEWS_EDITOR',
        pagePath: '/admin/news',
        groupId: 'ADMIN',
        iconCss: 'fa-solid fa-newspaper',
        dependantOnFeatureId: null,
        sortingPriority: 6000,
        visibleForRoles: {
          ADMIN: true
        }
      },
      {
        id: 'STANDARD_CUSTOM_PAGES_MENU_TITLE',
        title: 'CUSTOM_PAGES_MENU_TITLE',
        pagePath: '/admin/custom-page-editor',
        groupId: 'ADMIN',
        iconCss: 'fa-solid fa-memo',
        dependantOnFeatureId: null,
        sortingPriority: 5000,
        visibleForRoles: {
          ADMIN: true
        }
      },
      {
        id: 'STANDARD_SCHEDULERS',
        title: 'SCHEDULERS',
        pagePath: '/admin/schedulers-monitor',
        groupId: 'SYSTEM',
        iconCss: 'fa-solid fa-calendar-days',
        dependantOnFeatureId: null,
        sortingPriority: 4000,
        visibleForRoles: {
          ADMIN: true
        }
      },
      {
        id: 'STANDARD_CACHES',
        title: 'CACHES',
        pagePath: '/admin/manage-caches',
        groupId: 'SYSTEM',
        iconCss: 'fa-solid fa-hard-drive',
        dependantOnFeatureId: null,
        sortingPriority: 3000,
        visibleForRoles: {
          ADMIN: true
        }
      },
      {
        id: 'STANDARD_TRANSLATIONS',
        title: 'TRANSLATIONS',
        pagePath: '/admin/translations',
        groupId: 'SYSTEM',
        iconCss: 'fa-solid fa-language',
        dependantOnFeatureId: null,
        sortingPriority: 2000,
        visibleForRoles: {
          ADMIN: true
        }
      },
      {
        id: 'STANDARD_ACTUATOR',
        title: 'ACTUATOR',
        pagePath: '/admin/actuator',
        groupId: 'SYSTEM',
        iconCss: 'fa-solid fa-user-crown',
        sortingPriority: 1000,
        dependantOnFeatureId: null,
        visibleForRoles: {
          ACTUATOR: true
        }
      }
    ];
  }
}
