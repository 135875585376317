import { Component, OnInit, Input, OnChanges, SimpleChange, SimpleChanges, OnDestroy } from '@angular/core';
import { Availability } from '../../models/availability';
import { Subscription } from 'rxjs';
import { AvailabilityHelperService } from '../../services/availability-helper.service';
import { first } from 'rxjs';
import { GungModalService } from '../../services/gung-modal/gung-modal.service';
import { Product } from '../../models';
import { AvailabilityService } from '../../services/availability/availability.service';

@Component({
  selector: 'lib-availability',
  templateUrl: './availability.component.html',
  styleUrls: ['./availability.component.css']
})
export class AvailabilityComponent implements OnInit, OnChanges {
  @Input()
  availability: Availability;
  @Input()
  isSales = false;

  @Input()
  product?: Product;


  @Input()
  details?: boolean;

  status: string;

  class: { [className: string]: boolean };

  constructor(
    protected availabilityHelper: AvailabilityHelperService,
    protected gungModalService: GungModalService,
    protected availabilityService: AvailabilityService
  ) { }

  ngOnInit() {

  }

  ngOnChanges() {
    this.availabilityHelper
      .getAvailabilityDisplay(this.availability, this.product, this.details)
      .pipe(first())
      .subscribe(d => {
        this.status = d.value;
        this.class = d.class;
      });
  }

  openModal() {
    if (this.availability || this.product) {
      this.availabilityService.getAvailability(this.availability?.productId || this.product?.id, undefined, true).pipe(first()).subscribe(availability => {
        if (!!availability) {
          this.availability = availability;
          this.gungModalService.openAvailabilityModal(this.availability, { size: 'lg' }, this.product).then(
            result => { },
            reason => { }
          );
        }
      })
    }
  }
}
