import { Injectable, Optional, Inject } from '@angular/core';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class StripeConfigService {
  constructor(
    @Optional()
    @Inject('environment')
    protected environment: { [s: string]: any }
  ) {}

  /**
   * The default implementation is to return the Stripe public key for the current environment.
   *
   * @returns The Stripe public key for the current environment
   */
  public getStripePublicKey(): Observable<string> {
    return of(this.environment.stripePkLive);
  }
}
