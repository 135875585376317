import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CheckoutObject } from '../../models/checkout';

export interface OrderStripe {
  id: string;
  orderId: string;
  stripeId: string;
}

@Injectable({
  providedIn: 'root'
})
export class StripeService {
  // https://stripe.com/docs/js/appendix/supported_locales
  private stripeSupportedLocales = [
    'bg',
    'cs',
    'da',
    'de',
    'el',
    'en',
    'en-GB',
    'es',
    'es-419',
    'et',
    'fi',
    'fr',
    'fr-CA',
    'hu',
    'id',
    'it',
    'ja',
    'lt',
    'lv',
    'ms',
    'mt',
    'nb',
    'nl',
    'pl',
    'pt',
    'pt-BR',
    'ro',
    'ru',
    'sk',
    'sl',
    'sv',
    'tr',
    'zh',
    'zh-HK',
    'zh-TW'
  ];

  constructor(protected http: HttpClient) {}

  createSession(checkout: CheckoutObject): Observable<any> {
    return this.http.post<any>('json/session', checkout);
  }

  getLocale(): string {
    const selectedLanguage = localStorage.getItem('selectedLanguage') || '';
    if (this.stripeSupportedLocales.indexOf(selectedLanguage) >= 0) {
      return selectedLanguage;
    }

    // Stripe detects the locale of the browser
    return 'auto';
  }

  public getOrderStripe(storageId: string): Observable<OrderStripe> {
    const urlString = `json/stripe-order/${storageId}`;
    return this.http.get<OrderStripe>(urlString);
  }
}
