import { Component } from '@angular/core';
import { TemplateComponent } from '../../custom-page-interface/template-component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { CustomPageTemplateComponent } from '../../custom-page-class/custom-page-template-component';
import { GungFlowService } from '../../../../services/gung-flow/gung-flow.service';
import { AssortmentService } from '../../../../services/assortment.service';
import { TranslateService } from '@ngx-translate/core';
import { CustomPageConfigService } from '../../../../services/custom-page/custom-page-config.service';
import { GungNoImageUrl } from 'gung-common';


// DEFAULT DATA EXAMPLE USED WHEN NO DATA
const dataDefault: TemplateComponent = {
  templateName: null,
  elements: {
    topImage: {
      link: {
        enabled: true,
        internal: true,
        url: ''
      },
      image: {
        enabled: true,
        sourceUrl: GungNoImageUrl // 1400x1400
      },
      text: {
        enabled: true,
        text: ''
      }
    },
    leftImage: {
      link: {
        enabled: true,
        internal: true,
        url: ''
      },
      image: {
        enabled: true,
        sourceUrl: GungNoImageUrl // 300x300
      },
      text: {
        enabled: true,
        text: ''
      }
    },
    middleImage: {
      link: {
        enabled: true,
        internal: true,
        url: ''
      },
      image: {
        enabled: true,
        sourceUrl: GungNoImageUrl // 300x300
      },
      text: {
        enabled: true,
        text: ''
      }
    },
    rightImage: {
      link: {
        enabled: true,
        internal: true,
        url: ''
      },
      image: {
        enabled: true,
        sourceUrl: GungNoImageUrl // 300x300
      },
      text: {
        enabled: true,
        text: ''
      }
    }
  }
};

@Component({
  selector: 'lib-home-default-template',
  templateUrl: './home-default-template.component.html',
  styleUrls: ['./home-default-template.component.css']
})
export class HomeDefaultTemplateComponent extends CustomPageTemplateComponent {

  get moreImage(): string[] {
    return Object.keys(this.data.elements).filter(key => key.includes('moreImage')).sort();
  }

  getDefaultData(): TemplateComponent {
    return dataDefault;
  }

  public addImage() {
    const id = Object.keys(this.data.elements).filter(key => key.includes('moreImage')).sort().pop()?.replace('moreImage', '') || 0;
    this.data.elements['moreImage' + (Number(id) + 1)] = {
      link: {
        enabled: true,
        internal: true,
        url: ''
      },
      image: {
        enabled: true,
        sourceUrl: GungNoImageUrl
      },
      text: {
        enabled: true,
        text: ''
      }
    };
  }

  removeImage(image) {
    delete this.data.elements[image];
  }
}
