import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HubspotContacts } from '../../models/hubspot-contacts';
import { BehaviorSubject, Observable, ReplaySubject, tap } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HubspotContactService {
  private hubspotContacts: BehaviorSubject<HubspotContacts[]> = new BehaviorSubject<HubspotContacts[]>([]);
  private hubspotContactsByCustomerId: { [s: string]: BehaviorSubject<HubspotContacts[]> } = {};

  constructor(protected http: HttpClient) {}

  getHubspotContacts(customerId: string, nocache = false): Observable<HubspotContacts[]> {
    const headers = {
      maxAge: nocache ? '-1' : '300'
    };
    const url = 'json/hubspot/contacts/by-company-erp-id?companyErpId=' + customerId;
    return this.http.get<HubspotContacts[]>(url, { headers }).pipe(
      tap(contacts => {
        this.hubspotContacts.next(contacts);
        this.hubspotContactsByCustomerId[customerId].next(contacts);
      })
    );
  }

  getHubspotContactsObservable(customerId: string): Observable<HubspotContacts[]> {
    if (!this.hubspotContactsByCustomerId[customerId]) {
      this.hubspotContactsByCustomerId[customerId] = new BehaviorSubject<HubspotContacts[]>([]);
      this.getHubspotContacts(customerId).subscribe(_ => { });
    }
    return this.hubspotContactsByCustomerId[customerId].asObservable();
  }

  createHubspotContact(customerId: string, hubspotContact): Observable<HubspotContacts> {
    const url = 'json/hubspot/contacts?companyErpId=' + customerId;
    return this.http
      .post<HubspotContacts>(url, hubspotContact)
      .pipe(tap(c => this.getHubspotContacts(customerId, true).subscribe(_ => {})));
  }

  editHubspotContact(customerId: string, hubspotContact: HubspotContacts): Observable<HubspotContacts> {
    const url = `json/hubspot/contacts/${hubspotContact.extra.hubspotContact.id}?companyErpId=` + customerId;
    return this.http
      .put<HubspotContacts>(url, hubspotContact)
      .pipe(tap(c => this.getHubspotContacts(customerId, true).subscribe(_ => {})));
  }
}
