import { Injectable } from '@angular/core';
import { AppState } from '../../state/state.module';
import { Store, select } from '@ngrx/store';
import {
  selectCartRowByProductId,
  selectAllCartContents,
  selectCartRowByProductIdProductPartialId
} from '../../state/cart/selectors';
import { Observable, forkJoin, of } from 'rxjs';
import { map, first } from 'rxjs';
import {
  Add,
  BulkAdd,
  Subtract,
  SetQuantity,
  Remove,
  Clear,
  SetExtra,
  RemoveExtra,
  QuantityPayload,
  BulkSetQuantity,
  BulkSetExtra,
  BulkRemove,
  SetProductPartialId,
  CartReorder,
  ReplaceCartRow
} from '../../state/cart/actions';
import { CartRow } from '../../state/cart/types';
import { AuthService } from '../auth/auth.service';
import { User } from '../../state/auth/types';
import { Angulartics2 } from 'angulartics2';
import { GungGoogleTagManagerService } from '../google-tag-manager/gung-google-tag-manager.service';
import { Optional } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CartService {
  public canEditRows = true;
  public showInputButtons = false;

  constructor(
    protected store: Store<AppState>,
    protected authService: AuthService,
    @Optional() protected gungGoogleTagManagerService: GungGoogleTagManagerService
  ) { }

  addToCart(
    productId: string,
    qty: number,
    targetStockId?: string,
    productPartialId?: string,
    rownr?: number,
    extra?: { [s: string]: any },
    position?: number,
    productName?: string
  ): void {
    if (this.gungGoogleTagManagerService) {
      this.gungGoogleTagManagerService.cartRowAdded(productId, qty, productName);
    }

    if (!targetStockId) {
      this.authService
        .getCurrentUser()
        .pipe(first())
        .subscribe(user => {
          const stockId = user.managedMultistockIds[0];
          this.store.dispatch(
            new Add({
              productId,
              productPartialId,
              targetStockId: stockId,
              qty,
              rownr,
              extra,
              position
            })
          );
        });
    } else {
      this.store.dispatch(
        new Add({
          productId,
          productPartialId,
          targetStockId,
          qty,
          rownr,
          extra,
          position
        })
      );
    }
  }

  private getQuantityPayload(
    productId: string,
    qty: number,
    targetStockId?: string,
    productPartialId?: string,
    extra?: { [s: string]: any },
    position?: any
  ): Observable<QuantityPayload> {
    if (!targetStockId) {
      return this.authService.getCurrentUser().pipe(
        first(),
        map((user: User) => {
          const payload: QuantityPayload = {
            productId,
            productPartialId,
            targetStockId: user.managedMultistockIds[0],
            qty,
            extra,
            position
          };
          return payload;
        })
      );
    }

    return of({
      productId,
      productPartialId,
      targetStockId,
      qty,
      extra,
      position
    });
  }

  bulkAddToCart(
    data: {
      productId: string;
      qty: number;
      targetStockId?: string;
      productPartialId?: string;
      extra?: { [s: string]: any };
      position?: number;
      productName?: string;
    }[]
  ): void {
    if (this.gungGoogleTagManagerService) {
      data.forEach(d => {
        this.gungGoogleTagManagerService.cartRowAdded(d.productId, d.qty, d.productName);
      });
    }

    forkJoin(
      data.map(d =>
        this.getQuantityPayload(d.productId, d.qty, d.targetStockId, d.productPartialId, d.extra, d.position).pipe(
          first()
        )
      )
    ).subscribe((payload: QuantityPayload[]) => {
      this.store.dispatch(new BulkAdd(payload));
    });
  }

  subtractFromCart(productId: string, qty: number, targetStockId?: string, productPartialId?: string): void {
    if (!targetStockId) {
      this.authService
        .getCurrentUser()
        .pipe(first())
        .subscribe(user => {
          const stockId = user.managedMultistockIds[0];
          this.store.dispatch(
            new Subtract({
              productId,
              productPartialId,
              targetStockId: stockId,
              qty
            })
          );
        });
    } else {
      this.store.dispatch(
        new Subtract({
          productId,
          productPartialId,
          targetStockId,
          qty
        })
      );
    }
  }

  getProductQty(productId: string, productPartialId?: string): Observable<number> {
    if (productPartialId) {
      return this.store
        .pipe(select(selectCartRowByProductIdProductPartialId({ productId, productPartialId })))
        .pipe(map(r => (r ? r.qty : 0)));
    }
    return this.store.pipe(select(selectCartRowByProductId({ productId }))).pipe(map(r => (r ? r.qty : 0)));
  }

  getProductExtra(productId: string, productPartialId?: string): Observable<{ [s: string]: any }> {
    if (productPartialId) {
      return this.store
        .pipe(select(selectCartRowByProductIdProductPartialId({ productId, productPartialId })))
        .pipe(map(r => (r ? r.extra : {})));
    }
    return this.store.pipe(select(selectCartRowByProductId({ productId }))).pipe(map(r => (r ? r.extra : {})));
  }

  setProductQuantity(productId: string, qty: number, targetStockId?: string, productPartialId?: string): void {
    if (!targetStockId) {
      this.authService
        .getCurrentUser()
        .pipe(first())
        .subscribe(user => {
          const stockId = user.managedMultistockIds[0];
          this.store.dispatch(
            new SetQuantity({
              productId,
              productPartialId,
              targetStockId: stockId,
              qty
            })
          );
        });
    } else {
      this.store.dispatch(
        new SetQuantity({
          productId,
          productPartialId,
          targetStockId,
          qty
        })
      );
    }
  }

  bulkSetProductQuantity(
    data: { productId: string; qty: number; targetStockId?: string; productPartialId?: string }[]
  ): void {
    forkJoin(
      data.map(d => this.getQuantityPayload(d.productId, d.qty, d.targetStockId, d.productPartialId).pipe(first()))
    ).subscribe((payload: QuantityPayload[]) => {
      this.store.dispatch(new BulkSetQuantity(payload));
    });
  }

  getCurrentCart(): Observable<CartRow[]> {
    return this.store.pipe(select(selectAllCartContents));
  }

  removeRow(cartRow: CartRow) {
    return this.store.dispatch(
      new Remove({
        productId: cartRow.productId,
        productPartialId: cartRow.productPartialId,
        targetStockId: cartRow.targetStockId
      })
    );
  }

  bulkRemoveRows(cartRows: CartRow[]) {
    return this.store.dispatch(
      new BulkRemove(
        cartRows.map(cartRow => {
          return {
            productId: cartRow.productId,
            productPartialId: cartRow.productPartialId,
            targetStockId: cartRow.targetStockId,
            qty: 0
          };
        })
      )
    );
  }

  clearCart() {
    this.store.dispatch(new Clear());
  }

  bulkSetExtra(
    data: {
      productId: string;
      extra: { [s: string]: any };
      targetStockId?: string;
      productPartialId?: string;
      replace?: boolean;
    }[]
  ): void {
    this.store.dispatch(new BulkSetExtra(data));
  }

  setExtra(
    productId: string,
    extra: { [s: string]: any },
    targetStockId?: string,
    productPartialId?: string,
    // Add this parameter to be able to remove fields from extra, otherwise a merge is done and removed fields are sill present
    replace?: boolean
  ) {
    if (!targetStockId) {
      this.authService
        .getCurrentUser()
        .pipe(first())
        .subscribe(user => {
          const stockId = user.managedMultistockIds[0];
          this.store.dispatch(
            new SetExtra({
              extra,
              productId,
              productPartialId,
              targetStockId: stockId,
              replace
            })
          );
        });
    } else {
      this.store.dispatch(
        new SetExtra({
          extra,
          productId,
          productPartialId,
          targetStockId,
          replace
        })
      );
    }
  }

  removeExtraField(productId: string, field: string, targetStockId?: string, productPartialId?: string) {
    if (!targetStockId) {
      this.authService
        .getCurrentUser()
        .pipe(first())
        .subscribe(user => {
          const stockId = user.managedMultistockIds[0];
          this.store.dispatch(
            new RemoveExtra({
              productId,
              productPartialId,
              targetStockId: stockId,
              value: field
            })
          );
        });
    } else {
      this.store.dispatch(
        new RemoveExtra({
          productId,
          productPartialId,
          targetStockId,
          value: field
        })
      );
    }
  }

  setProductPartialId(productId: string, targetStockId?: string, productPartialId?: string, oldProductPartialId?: string): void {
    if (!targetStockId) {
      this.authService
        .getCurrentUser()
        .pipe(first())
        .subscribe(user => {
          const stockId = user.managedMultistockIds[0];
          this.store.dispatch(
            new SetProductPartialId({
              productId,
              productPartialId,
              targetStockId: stockId,
              oldProductPartialId
            })
          );
        });
    } else {
      this.store.dispatch(
        new SetProductPartialId({
          productId,
          productPartialId,
          targetStockId,
          oldProductPartialId
        })
      );
    }
  }

  reorderCart(cartOrder: { productId: string; targetStockId?: string; productPartialId?: string }[]) {
    this.store.dispatch(new CartReorder(cartOrder));
  }

  public replaceCartRow(currentCartRow: CartRow, newCartRow: CartRow): void {
    this.store.dispatch(new ReplaceCartRow(currentCartRow, newCartRow));
  }
}
