import { AfterViewInit, Component, ElementRef, Input, ViewChild } from '@angular/core';
import { getDocument, GlobalWorkerOptions, version } from 'pdfjs-dist/legacy/build/pdf';
import { GungDownloadUrl } from '../../services/gung-image-url/gung-image-url.service';
import { S3Item } from '../../services/s3-upload/s3-upload.service';

@Component({
  selector: 'lib-gung-pdf-viewer',
  templateUrl: './gung-pdf-viewer.component.html',
  styleUrl: './gung-pdf-viewer.component.scss'
})
export class GungPdfViewerComponent implements AfterViewInit {
  @ViewChild('thumbnail')
  public thumbnail: ElementRef;

  @Input()
  file: string | S3Item;

  @Input()
  public showPageNumber: number = 1;

  @Input()
  height: number;
  @Input()
  width: number;

  ngAfterViewInit(): void {
    if (this.file) {
      (this.thumbnail.nativeElement as HTMLDivElement).innerHTML = '<i class="fa-solid fa-spinner fa-pulse fa-5x"></i>';
      this.getPdf2(this.file);
    }
  }

  async getPdf2(file: string | S3Item) {
    GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${version}/pdf.worker.min.js`;
    // Loading file from file system into typed array
    const pdfPath = GungDownloadUrl(file);
    try {
      const loadingTask = getDocument(pdfPath);
      const pdfDocument = await loadingTask.promise;
      const numPages = pdfDocument.numPages;
      if (this.showPageNumber > numPages) {
        console.error('GungPdfViewerComponent - requested page > total page');
        this.showPageNumber = 1;
      }
      // Get the first page.
      const page = await pdfDocument.getPage(this.showPageNumber);
      // Render the page on a Node canvas with 100% scale.

      // draw page to fit into 96x96 canvas
      var vp = page.getViewport({ scale: 1, });
      var canvas = document.createElement("canvas");
      var scalesize = 1;
      canvas.width = vp.width * scalesize;
      canvas.height = vp.height * scalesize;
      var scale = Math.min(canvas.width / vp.width, canvas.height / vp.height);
      const image = await page.render({ canvasContext: canvas.getContext("2d"), viewport: page.getViewport({ scale: scale }) }).promise.then(function () {
        return canvas;
      });

      var div: HTMLDivElement = this.thumbnail.nativeElement;
      var img = new Image();
      img.src = image.toDataURL();
      if (this.height) {
        img.height = this.height;
      }
      if (this.width) {
        img.width = this.width;
      }
      div.innerHTML = '';
      div.appendChild(img);

      // Release page resources.
      page.cleanup();
    } catch (reason) {
      console.error(reason);
    }
    return true;
  }
}
