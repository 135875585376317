import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GungBase } from '../../models';

export interface HubspotActivities extends GungBase{

}

@Injectable({
  providedIn: 'root'
})
export class HubspotActivitiesService {

  constructor(protected http: HttpClient) {}

  public getActivities(erpId: string): Observable<HubspotActivities[]> {
    const url = `json/hubspot/activities/by-company-erp-id?companyErpId=${erpId}`;
    return this.http.get<HubspotActivities[]>(url, {});
  }

  public createActivity(erpId, description): Observable<HubspotActivities[]>{
    const url = `json/hubspot/activities?companyErpId=${erpId}`;
    const payload={
      extra: {
        hubspotActivity: {
          properties:{
            hs_note_body: description
          }
        }
        
      }
    }
    return this.http.post<HubspotActivities[]>(url, payload);
  }
}
