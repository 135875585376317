import { Component, OnDestroy, OnInit, Optional } from '@angular/core';
import { OptionsList, DateUtilService } from 'gung-common';
import {
  Customer,
  Product,
  CartRowPrice,
  CustomerService,
  ProductService,
  AuthService,
  PriceService,
  MetadataService,
  CartService,
  BackendFeatureService,
  SelectedCustomerService,
  GungGoogleTagManagerService,
  CheckoutRow,
  TableRecord
} from 'gung-standard';
import { SummarizeOrderJeevesComponent, JeevesDeliveryAddressesService } from 'gung-standard-jeeves';
import { environment } from '../../../../environments/environment';
import { Subject, first, takeUntil, mergeMap, forkJoin, of, map } from 'rxjs';
import { HlDisplayDummyArticleService } from '../../../services/hl-display-dummy-article.service';
import { HlDisplayOrderService } from '../../../services/hl-display-order.service';
import { langExists } from '../../../services/hl-product.service';
import { ActionType, HlDisplayMsflowArgumentService } from '../../../services/hl-display-msflow-argument.service';
import {
  CheckoutSettingsGroup,
  HlDisplayPortalFeaturesService
} from '../../../services/hl-display-portal-features.service';

@Component({
  selector: 'app-hl-summarize-order-step-portal',
  templateUrl: './hl-summarize-order-step-portal.component.html',
  styleUrl: './hl-summarize-order-step-portal.component.css'
})
export class HlSummarizeOrderStepPortalComponent extends SummarizeOrderJeevesComponent implements OnInit, OnDestroy {
  private orderLineItemNotInJeevesProductId = ['155035', '900070'];

  customer: Customer;
  billingCustomer: Customer;
  productsMap?: { [productId: string]: Product };
  pricesMap?: { [productId: string]: CartRowPrice };
  today: Date = new Date();

  showDates: boolean = true;

  public showApprovalQuote = false;

  productsFullMap?: { [productId: string]: Product };
  protected unsubscribe: Subject<void> = new Subject();

  selectInvoiceCustomerEnabled: boolean = false;
  selectInvoiceCustomerOptionsList: OptionsList[] = [];
  selectInvoiceCustomer: Customer[];

  isExternalProjectManagers = false;
  isStores = false;

  constructor(
    protected customerService: CustomerService,
    protected productService: ProductService,
    protected authService: AuthService,
    priceService: PriceService,
    public dateUtilService: DateUtilService,
    private metadataService: MetadataService,
    private cartService: CartService,
    public dummyArticleService: HlDisplayDummyArticleService,
    protected jeevesDeliveryAddressesService: JeevesDeliveryAddressesService,
    protected orderService: HlDisplayOrderService,
    protected backendFeatureService: BackendFeatureService,
    private selectedCustomerService: SelectedCustomerService,
    protected hlDisplayMsflowArgumentService: HlDisplayMsflowArgumentService,
    @Optional()
    protected gungGoogleTagManagerService: GungGoogleTagManagerService,
    protected hlDisplayPortalFeaturesService: HlDisplayPortalFeaturesService
  ) {
    super(
      customerService,
      productService,
      authService,
      priceService,
      dateUtilService,
      jeevesDeliveryAddressesService,
      gungGoogleTagManagerService
    );
  }

  ngOnInit() {
    super.ngOnInit();

    this.hlDisplayPortalFeaturesService
      .getPortalFeatureGroup('orderCheckoutSettingsGroup')
      .pipe(
        first(),
        map(checkoutSettings => checkoutSettings as CheckoutSettingsGroup)
      )
      .subscribe(checkoutSettings => {
        this.showApprovalQuote = checkoutSettings.showApprovalQuote;
        //Fallback before all settings are updated in the backed (if undefined --> show dates)
        this.showDates = checkoutSettings.showDateInCheckout;
        if (this.showDates === undefined) {
          this.showDates = true;
        }
        if (checkoutSettings.showApprovalQuote) {
          this.getProductImages();
        }
      });

    this.backendFeatureService.isActivated('select-invoice-customer').subscribe(isActivated => {
      this.selectInvoiceCustomerEnabled = isActivated;
      if (isActivated) {
        this.orderService.getSelectableInvoiceCustomers().subscribe(data => {
          const currentValue = this.customer.extra._billing?.ftgnr || '';
          this.selectInvoiceCustomerOptionsList = data.map(d => ({
            id: d.id,
            name: d.id
          }));
          this.selectInvoiceCustomerOptionsList.unshift({
            id: currentValue,
            name: currentValue
          });
          this.selectInvoiceCustomer = data;
        });
      }
    });

    this.selectedCustomerService
      .getSelectedCustomer()
      .pipe(first())
      .subscribe(customer => {
        this.checkout.extra.oh.dellevtillaten = customer.extra.kus.dellevtillaten;
        this.checkout.extra.oh.transportorskod = customer.extra.kus.transportorskod;
      });

    this.authService
      .getCurrentUser()
      .pipe(first())
      .subscribe(user => {
        if (user.roles.includes('SALES')) {
          this.checkout.extra.oh.vref = user.name;
        } else {
          this.checkout.extra.oh.vref = 'Customer service';
        }
      });
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  getName(product, orderRow): string {
    if (!this.sales) {
      return product.name;
    }

    if (this.orderLineItemNotInJeevesProductId.includes(product.id)) {
      return orderRow.extra.orp.artbeskr;
    }

    const id = product.id;

    const itemGroup = this.metadataService.getMetadataValue('vg', 'varugruppbeskr', product.extra.ar.varugruppkod);
    let itemDesc = product.extra.ar.artbeskr;
    return `${itemGroup} - ${itemDesc}`;
  }

  getProductImages() {
    this.cartService
      .getCurrentCart()
      .pipe(
        takeUntil(this.unsubscribe),
        mergeMap(cartRows => {
          const ids = cartRows.map(row => row.productId);
          return forkJoin([of(cartRows), this.productService.getFullProductsByIds(ids).pipe(first())]);
        })
      )
      .subscribe(([cartRows, products]) => {
        this.productsFullMap = {};
        for (const product of products) {
          this.productsFullMap[product.id] = product;
          product?.extra?.images?.map(i => delete i.isSelected);
          // Get previous selected images
          const row = cartRows.find(r => r.productId === product.id);
          if (!row || !row.extra.orp || !row.extra.orp.edit) {
            continue;
          }
          const checkoutRow = this.checkout.rows.find(r => r.id === product.id);
          if (checkoutRow) {
            checkoutRow.extra.orp.edit = row.extra.orp.edit;
          }
          if (
            row &&
            row.extra.orp.edit &&
            row.extra.orp.edit.trim() !== '' &&
            row.extra.orp.edit.split(';').length > 0
          ) {
            for (const imgS3Uri of row.extra.orp.edit.split(';')) {
              const imgIndex = this.productsFullMap[product.id].extra.images.findIndex(
                i => 'filters:etag(' + i.s3ETag + ')/' + i.s3Uri === imgS3Uri
              );
              if (imgIndex !== -1 && this.productsFullMap[product.id].extra.images[imgIndex]) {
                this.productsFullMap[product.id].extra.images[imgIndex].isSelected = true;
              }
            }
          }
        }
      });
  }

  checkImageSelected(row: CheckoutRow, image): boolean {
    if (row?.extra?.orp?.edit?.trim() !== '') {
      const selected: string[] = row.extra.orp.edit.split(';');
      if (selected.length > 0) {
        return selected.indexOf(image.s3Uri) > -1;
      }
    }
    return false;
  }

  selectImage(row: CheckoutRow, image) {
    image.isSelected = !image.isSelected;
    if (!row.extra.orp.edit) {
      row.extra.orp.edit = '';
    }

    const orpEdit = row.extra.orp.edit.trim() === '' ? [] : row.extra.orp.edit.split(';');
    if (image.isSelected) {
      // Count type of all already selected images
      const countImage = {};
      if (orpEdit.length > 0) {
        for (const imgS3Uri of orpEdit) {
          const img = this.productsFullMap[row.id].extra.images.find(
            i => 'filters:etag(' + i.s3ETag + ')/' + i.s3Uri === imgS3Uri
          );
          if (!img) {
            return;
          }
          const pictureType = img.extra.PictureType; // LineDrawing, ProductPicture
          if (!countImage[pictureType]) {
            countImage[pictureType] = 0;
          }
          countImage[pictureType]++;
        }
      }
      if (!this.maxSelectedImage(countImage, image.extra.PictureType)) {
        orpEdit.push('filters:etag(' + image.s3ETag + ')/' + image.s3Uri);
        row.extra.orp.edit = orpEdit.join(';');
      } else {
        image.isSelected = false;
      }
    } else {
      // Remove deselected image
      row.extra.orp.edit = orpEdit.filter(i => i !== 'filters:etag(' + image.s3ETag + ')/' + image.s3Uri).join(';');
    }

    this.cartService.setExtra(
      row.id,
      {
        orp: {
          // ...row.extra.orp,
          edit: row.extra.orp.edit
        }
      },
      row.targetStockId,
      row.partialId
    );
  }

  /**
   * Check if selected images reach the max allow selection
   */
  maxSelectedImage(countImage, type): boolean {
    return false; // Remove max selection restriction
    if (!countImage[type]) {
      return false;
    }
    switch (type) {
      case 'ProductPicture':
        return countImage[type] >= 2;
      case 'LineDrawing':
      case 'StorePicture': // CONFIRM THIS
      default:
        return countImage[type] >= 1;
    }
  }

  selectInvoiceCustomerOption(value) {
    if (!this.billingCustomer) {
      this.billingCustomer = this.customer;
    }
    this.customer = this.selectInvoiceCustomer.find(c => c.id === value);
    if (!this.customer) {
      this.customer = this.billingCustomer;
    }
  }

  filterCarrierCodes(option: { key: string; value: string | TableRecord }): boolean {
    return !(option.value + '').toLocaleLowerCase().startsWith('zz');
  }

  protected nextBtnClickedCb(nextInfo?: any) {
    let checkout = this.checkout;
    let sendCheckout = checkout;
    if (nextInfo.isQuote || nextInfo.isApprovalQuote) {
      sendCheckout = {
        ...sendCheckout,
        extra: {
          ...sendCheckout.extra,
          _approval_quote: nextInfo.isApprovalQuote ? nextInfo.isApprovalQuote : undefined,
          oh: {
            ...checkout.extra.oh,
            ordstat: 0
          }
        }
      };

      sendCheckout.extra[this.hlDisplayMsflowArgumentService.getArgumentKey()] =
        this.hlDisplayMsflowArgumentService.createMsFlowArguments(ActionType.Offer, this.sales);
    }
    if (nextInfo.isSampleOrder) {
      sendCheckout = {
        ...sendCheckout,
        extra: {
          ...sendCheckout.extra,
          oh: {
            ...checkout.extra.oh,
            kundfraktdeb: 0, // Charge freight box
            q_hl_chargepalletcost: '0' // Box Charge Pallet Cost
          },
          opr: {
            ...checkout.extra.opr,
            vb_pris: 0 // PrcCurr
          },
          procargs: {
            ...checkout.extra.procargs,
            ordtyp: 203 // Order type
          }
        }
      };
      sendCheckout.extra[this.hlDisplayMsflowArgumentService.getArgumentKey()] =
        this.hlDisplayMsflowArgumentService.createMsFlowArguments(ActionType.Sample, this.sales);
    }

    // If we don't have set the msflow arguments before this step (i.e it is not a sample or an offer) we can assume
    // that it is an order.
    if (!sendCheckout.extra[this.hlDisplayMsflowArgumentService.getArgumentKey()]) {
      sendCheckout.extra[this.hlDisplayMsflowArgumentService.getArgumentKey()] =
        this.hlDisplayMsflowArgumentService.createMsFlowArguments(ActionType.Order, this.sales);
    }

    this.stepDone.emit(sendCheckout);
  }
}
