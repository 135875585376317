<div class="row" *ngIf="!!customer">
  <div class="col-12">
    <h2 class="mt-4 mb-4">
      {{ customer.extra.gme.displayName }} <small>{{ customer.extra.gme.displayId }}</small>
    </h2>
  </div>
  <div class="col-12">
    <lib-gung-tabset #ngbTab="gungTabset" orientation="vertical">
      <lib-gung-tab id="tab-address">
        <ng-template libGungTabTitle>
          <div class="tab-title">
            <i class="fa-solid fa-address-card"></i> <span>{{ 'ADDRESS' | translate }}</span>
          </div>
        </ng-template>
        <ng-template libGungTabContent>
          <div class="row">
            <div class="col-12 col-md-4">
              <h3 translate>CUSTOMER_INFORMATION</h3>
              <span class="d-block">{{ customer.extra.gme.address.name }}</span>
              <span class="d-block">{{ customer.extra.gme.address.address1 }}</span>
              <span class="d-block"
                >{{ customer.extra.gme.address.zipCode }} {{ customer.extra.gme.address.city }}</span
              >
              <span class="d-block">{{ customer.extra.gme.address.countryCode }}</span>
            </div>
            <div class="col-12 col-md-4">
              <h3 translate>BILLING_INFORMATION</h3>
              <span class="d-block">{{ customer.extra.gme.billingAddress.name }}</span>
              <span class="d-block">{{ customer.extra.gme.billingAddress.address1 }}</span>
              <span class="d-block"
                >{{ customer.extra.gme.billingAddress.zipCode }} {{ customer.extra.gme.billingAddress.city }}</span
              >
              <span class="d-block">{{ customer.extra.gme.billingAddress.countryCode }}</span>
            </div>
          </div>

          <div class="row mt-4">
            <h3 translate>DELIVERY_ADDRESSES</h3>
            <div class="col-12 table-responsive gung-responsive">
              <table class="table table-borderless table-striped">
                <thead>
                  <tr>
                    <th translate>CODE</th>
                    <th translate>NAME</th>
                    <th translate>ADDRESS</th>
                    <th translate>GLN</th>
                    <th translate>ZIP</th>
                    <th translate>COUNTRY_CODE</th>
                    <th translate>PHONE</th>
                    <th translate>EMAIL</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let address of customer.extra?.gme?.deliveryAddresses">
                    <td [attr.data-label]="'CODE' | translate">
                      {{ address.code }}
                    </td>
                    <td [attr.data-label]="'NAME' | translate">
                      {{ address.name }}
                    </td>
                    <td [attr.data-label]="'ADDRESS' | translate">
                      {{ address.address1 }}
                    </td>
                    <td [attr.data-label]="'GLN' | translate">
                      {{ address.gln }}
                    </td>
                    <td [attr.data-label]="'ZIP' | translate">
                      {{ address.zipCode }}
                    </td>
                    <td [attr.data-label]="'COUNTRY_CODE' | translate">
                      {{ address.countryCode }}
                    </td>
                    <td [attr.data-label]="'PHONE' | translate">
                      {{ address.phoneNo }}
                    </td>
                    <td [attr.data-label]="'EMAIL' | translate">
                      {{ address.email }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </ng-template>
      </lib-gung-tab>
      <lib-gung-tab id="tab-orders">
        <ng-template libGungTabTitle>
          <div class="tab-title">
            <i class="fa-solid fa-bags-shopping"></i> 
            <span>{{ 'ORDERS' | translate }}</span>
          </div>
         
        </ng-template>
        <ng-template libGungTabContent>
          <div class="row">
            <div class="col">
              <lib-order-list></lib-order-list>
            </div>
          </div>
        </ng-template>
      </lib-gung-tab>
      <lib-gung-tab id="tab-invoices">
        <ng-template libGungTabTitle>
          <div class="tab-title">
            <i class="fa-solid fa-file-invoice"></i>
            <span>{{ 'INVOICES' | translate }}</span> 
          </div>
          
        </ng-template>
        <ng-template libGungTabContent>
          <div class="row">
            <div class="col">
              <lib-invoice-list></lib-invoice-list>
            </div>
          </div>
        </ng-template>
      </lib-gung-tab>
      <lib-gung-tab [title]="'OFFERS' | translate" id="tab-offers" *ngIf="!isUser && enableOffers">
        <ng-template libGungTabContent>
          <div class="row">
            <div class="col">
              <lib-offer-list></lib-offer-list>
            </div>
          </div>
        </ng-template>
      </lib-gung-tab>
      <lib-gung-tab *ngIf="showActivities && isSalesOrAdmin" [title]="'ACTIVITIES' | translate" id="lib-gung-tab-5">
        <ng-template libGungTabContent>
          <div class="row">
            <div class="col">
              <lib-activity-internal-list [customerId]="customer.id" [topFilter]="true"></lib-activity-internal-list>
            </div>
          </div>
        </ng-template>
      </lib-gung-tab>
      <lib-gung-tab *ngIf="!isUser" id="lib-gung-tab-6">
        <ng-template libGungTabTitle>
          <div class="tab-title">
            <i class="fa-solid fa-chart-mixed"></i> 
            <span>{{ 'ORDER_ANALYTICS' | translate }}</span>
          </div>
         
        </ng-template>
        <ng-template libGungTabContent>
          <div class="row">
            <div class="col">
              <lib-customer-order-analytics></lib-customer-order-analytics>
            </div>
          </div>
        </ng-template>
      </lib-gung-tab>
    </lib-gung-tabset>
  </div>
</div>
