<div class="modal-header" *ngIf="!!title">
  <h4 class="modal-title" id="modal-basic-title" translate>{{ title }}</h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body">
  <lib-gung-html-editor
    suppressLabel="true"
    placeholder="Type the content here!"
    [(ngModel)]="description"
  ></lib-gung-html-editor>
</div>
<div class="modal-footer" *ngIf="btnOk || btnCancel">
  <div class="row w-100">
    <div class="col-12">
      <div class="btn-group btn-group-justified w-100">
        <button class="btn btn-sm btn-outline-secondary btn-default" (click)="activeModal.dismiss()" translate>
          {{ btnCancel }}
        </button>
        <button class="btn btn-sm btn-primary btn-default" (click)="save()" translate>
          {{ btnOk }}
        </button>
      </div>
    </div>
  </div>
</div>
