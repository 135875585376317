<div class="modal-header">
  <h4 class="modal-title m-0" id="modal-basic-title">{{ selectedDigitalAsset.name }}</h4>
  <button type="button" class="close" aria-label="Close" (click)="crossClick()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <div class="row">
    <div class="col-12 col-md-6">
      <div
        class="assets-thumbs mx-auto d-flex flex-row flex-wrap"
        *ngIf="selectedDigitalAsset.images && selectedDigitalAsset.images[0] as image; else noimage"
      >
        <span *ngFor="let image of selectedDigitalAsset.images" class="p-2">
          <img
            class="img-fluid"
            [ngClass]="{ border: image.extra._selected }"
            (click)="selectImage(image)"
            [src]="image.s3Uri | gungImageUrl: 'type:thumbnail' : 'etag:' + image.s3ETag"
            alt="{{ image.description }}"
          />
          <a [href]="image | gungDownloadUrl: 'encodeURIComponent'" target="_blank">
            <i class="fas fa-download image-icon" [ngClass]="{ downloading: downloadingInProgress }"></i>
          </a>
        </span>
      </div>

      <ng-template #noimage>
        <img
          class="img-fluid w-100"
          [src]="'images/no-image.jpg' | gungImageUrl"
          alt="NO IMAGE"
          class="img-fluid mx-auto d-block"
        />
      </ng-template>
      <div class="row" *ngIf="!!selectedDigitalAsset.extra.links && selectedDigitalAsset.extra.links.length > 0">
        <div class="col-12">
          <h5><span translate>LINKS</span>:</h5>
          <ul style="list-style-type: disc">
            <li *ngFor="let link of selectedDigitalAsset.extra.links">
              <a href="{{ link }}" target="_blank">{{ link }}</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="col-12 col-md-6">
      <!-- <div class="form-group">
          <div class="row flex-fill">
            <div class="col-12 col-md-6 d-flex align-items-center">
              <label for="fileType" class="control-label" translate>FILE_TYPE</label>
            </div>
            <div class="col-12 col-md-6">
              <input class="form-control" type="text" name="fileType" id="fileType" [value]="selectedDigitalAsset.extra.fileType || 'zip'" readonly>
            </div>
          </div>
        </div> -->
      <!-- <div class="form-group">
          <div class="row flex-fill">
            <div class="col-12 col-md-6 d-flex align-items-center">
              <label for="size" class="control-label" translate>SIZE</label>
            </div>
            <div class="col-12 col-md-6">
              <input class="form-control" type="text" name="size" id="size" [value]="selectedDigitalAsset.extra.size || '-'" readonly>
            </div>
          </div>
        </div> -->
      <div class="form-group">
        <div class="row flex-fill">
          <div class="col-12 col-md-6 d-flex align-items-center">
            <label for="images" class="control-label" translate>NUMBER_OF_FILES</label>
          </div>
          <div class="col-12 col-md-6">
            <input
              class="form-control"
              type="text"
              name="images"
              id="images"
              [value]="(selectedDigitalAsset?.images?.length || 0) + (selectedDigitalAsset?.documents.length || 0)"
              readonly
            />
          </div>
        </div>
      </div>
      <!-- <div class="form-group">
          <div class="row flex-fill">
            <div class="col-12 col-md-6 d-flex align-items-center">
              <label for="dimensions" class="control-label" translate>DIMENSIONS</label>
            </div>
            <div class="col-12 col-md-6">
              <input class="form-control" type="text" name="dimensions" id="dimensions" [value]="selectedDigitalAsset.extra.dimensions || '-'" readonly>
            </div>
          </div>
        </div> -->
      <div class="form-group">
        <div class="row flex-fill">
          <div class="col-12 col-md-6 d-flex align-items-center">
            <label for="brand" class="control-label" translate>BRAND</label>
          </div>
          <div class="col-12 col-md-6">
            <!-- <input class="form-control" type="text" name="brand" id="brand" [value]="selectedDigitalAsset.extra.brand" readonly> -->
            <lib-gung-meta-output
              [horizontal]="true"
              [suppressLabel]="true"
              [value]="selectedDigitalAsset.extra.brand"
              metadataTable="TA40"
              metaOptionName="TX1"
            >
            </lib-gung-meta-output>
          </div>
        </div>
      </div>
      <div class="form-group">
        <div class="row flex-fill">
          <div class="col-12 col-md-6 d-flex align-items-center">
            <label for="tags" class="control-label" translate>TAGS</label>
          </div>
          <div class="col-12 col-md-6">
            <input
              class="form-control"
              type="text"
              name="tags"
              id="tags"
              [value]="selectedDigitalAsset.extra.tags || '-'"
              readonly
            />
          </div>
        </div>
      </div>
      <div class="form-group">
        <div class="row flex-fill">
          <div class="col-12 col-md-6 d-flex align-items-center">
            <label for="date" class="control-label" translate>DATE</label>
          </div>
          <div class="col-12 col-md-6">
            <input
              class="form-control"
              type="text"
              name="date"
              id="date"
              [value]="lastUpdateDate | date: dateUtilService.dateFormat"
              readonly
            />
          </div>
        </div>
      </div>
      <button
        type="button"
        class="btn btn-primary btn-sm"
        translate
        (click)="downloadAllImages(selectedDigitalAsset); saveClick()"
      >
        DOWNLOAD_ALL_IMAGES
      </button>
      <hr />
      <h5><span translate>FILES</span>:</h5>
      <ul class="list-unstyled">
        <li *ngFor="let document of selectedDigitalAsset?.documents">
          <input
            type="checkbox"
            aria-label="Checkbox for selection"
            class="mr-1"
            style="transform: scale(1.2)"
            [(ngModel)]="document.extra._selected"
          />
          <a (click)="downloadDocument(document)">{{ document.name }}</a>
        </li>
      </ul>
    </div>
  </div>
</div>
<div class="modal-footer">
  <div class="container p-3">
    <div class="row">
      <div class="align-items-center col-12 col-md-auto d-flex">
        <span translate>SELECTED_ITEM_COUNT</span>: {{ selectedTotal }}
      </div>
      <div class="col d-flex justify-content-end flex-wrap">
        <div ngbDropdown placement="top-right" class="d-inline-block dropup mr-2" *ngIf="selectedDigitalAsset">
          <button class="btn btn-primary export-area-buttons" id="dropdownSelection" ngbDropdownToggle translate>
            SELECTION_ALTERNATIVES
          </button>
          <div ngbDropdownMenu aria-labelledby="dropdownSelection" class="dropdown-container">
            <button class="export-area-buttons" ngbDropdownItem (click)="selectAll()">
              <span translate>SELECT_ALL_ASSETS</span>
            </button>
            <button class="export-area-buttons" ngbDropdownItem (click)="selectAllImages()">
              <span translate>SELECT_ALL_IMAGES</span>
            </button>
            <button class="export-area-buttons" ngbDropdownItem (click)="selectAllDocuments()">
              <span translate>SELECT_ALL_DOCUMENTS</span>
            </button>
            <button *ngIf="selectedTotal > 0" class="export-area-buttons" ngbDropdownItem (click)="clearAllSelection()">
              <span translate>DESELECT_ALL_ASSETS</span>
            </button>
          </div>
        </div>

        <div ngbDropdown placement="top-right" class="d-inline-block dropup" *ngIf="selectedDigitalAsset">
          <button
            type="button"
            class="btn btn-outline-primary"
            translate
            (click)="downloadAllSelected(selectedDigitalAsset)"
          >
            DOWNLOAD_ALL_SELECTED
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
