<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title" translate>SKIP_VALIDATION</h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body">
  <span class="d-block"><strong translate>EDI_ID</strong>: {{ ediOrderId }}</span>
  <span class="d-block"><strong translate>LINE</strong>: {{ index }}</span>
  @if (ediOrderItem?.ids?.gungId) {
    <span class="d-block"><strong translate>PRODUCT_ID</strong>: {{ ediOrderItem.ids.gungId }}</span>
  }
  @if (ediOrderItem?.quantities?.expectedQuantity) {
    <span class="d-block"
      ><strong translate>EXPECTED_QUANTITY</strong>: {{ ediOrderItem.quantities.expectedQuantity }}</span
    >
  }
  <br />
  <span class="d-block text-center bg-warning p-2 rounded col-6 mx-auto" translate>
    <strong> Ignore errors for this line during validation.</strong>
  </span>
</div>

<div class="modal-footer">
  <button class="btn btn-sm btn-primary btn-default px-4" (click)="save($event)" translate>YES</button>
  <button class="btn btn-sm btn-outline-primary px-4" (click)="activeModal.close(false)" translate>NO</button>
</div>
