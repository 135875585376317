import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, switchMap } from 'rxjs';
import { StandardStatisticsData } from '../../models/statistics';

@Injectable({
  providedIn: 'root'
})
export class CustomerStatisticsService {
  constructor(private http: HttpClient) {}

  getStatistics(): Observable<StandardStatisticsData[]> {
    const url = `json/company-statistics`;
    return this.http.get<StandardStatisticsData[]>(url);
  }

  getStatisticsByCustomer(customerId: string): Observable<StandardStatisticsData[]> {
    const url = `json/company-statistics/customer-statistics/${customerId}`;
    return this.http.get<StandardStatisticsData[]>(url).pipe(
        switchMap(data => {
            if (data === null) {
                const noCacheUrl = `json/company-statistics/customer-statistics/${customerId}/?noCache=true`;
                return this.http.get<StandardStatisticsData[]>(noCacheUrl);
            }
            return of(data);
        })
    );
}

  getStatisticsByUserHandledCustomers(): Observable<StandardStatisticsData[]> {
    const url = 'json/company-statistics/user-handled-customers-statistics';
    return this.http.get<StandardStatisticsData[]>(url);
  }
}
