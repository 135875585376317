import { Injectable } from '@angular/core';
import { DocumentTemplatesService } from 'gung-common';
import {
  SimpleConfigBaseFilter,
  ConfigBaseFilter,
  ListSortOption,
  ListLayout,
  ListLayoutMultipleComponent,
  SelectionAction,
  ExportSelection,
  ConfigService
} from 'gung-list';
import { first, Observable, of, switchMap } from 'rxjs';
import { map } from 'rxjs';
import { ActivityInternalTableComponent } from '../../components/activity-internal/activity-internal-table/activity-internal-table.component';
import {
  ActivityInternalExportService,
  ExportActivityInternalSelectionDataType
} from '../activity-internal-export/activity-internal-export.service';
import { ActivityInternal, ActivityInternalService } from '../activity-internal/activity-internal.service';
import { MetadataService } from '../metadata/metadata.service';

export class ActivityInternalFilter extends SimpleConfigBaseFilter<ActivityInternal> {
  constructor(private filterName: string, private filterKey: string) {
    super();
  }

  getOptionIds(item: ActivityInternal): string[] {
    return [item[this.filterKey]];
  }
  getOptionName(key: string): string {
    return key;
  }
  getName(): string {
    return this.filterName;
  }
}

export class ActivityInternalStatusFilter extends SimpleConfigBaseFilter<ActivityInternal> {
  constructor(private metadataService: MetadataService) {
    super();
  }

  getName(): string {
    return 'STATUS';
  }

  getOptionIds(item: ActivityInternal): string[] {
    const value = this.metadataService.getMetadataValue('ACTIVITYINTERNALSTATUS', 'name', item.status);
    if (!value) {
      return ['UNDEFINED'];
    }
    return [value];
  }

  getOptionName(key: string): string {
    return key;
  }
}

export class ActivityInternalTypeFilter extends SimpleConfigBaseFilter<ActivityInternal> {
  constructor(private metadataService: MetadataService) {
    super();
  }

  getName(): string {
    return 'TYPE';
  }

  getOptionIds(item: ActivityInternal): string[] {
    const value = this.metadataService.getMetadataValue('ACTIVITYINTERNALTYPE', 'name', item.type);
    if (!value) {
      return ['UNDEFINED'];
    }
    return [value];
  }

  getOptionName(key: string): string {
    return key;
  }
}

@Injectable({
  providedIn: 'root'
})
export class ActivityInternalListConfigService implements ConfigService<ActivityInternal> {

  topFilter = false;

  constructor(
    protected activityInternalService: ActivityInternalService,
    protected metadataService: MetadataService,
    protected documentTemplatesService: DocumentTemplatesService,
    protected activityInternalExportService: ActivityInternalExportService
  ) {}

  customerId: string;

  setCustomerId(customerId) {
    this.customerId = customerId;
  }

  getItems(): Observable<ActivityInternal[]> {
    return this.activityInternalService.getAll().pipe(
      map(result => {
        if (this.customerId) {
          return result.filter(r => r.customerId === this.customerId);
        }
        return result;
      }),
      map(result =>
        result.sort((a, b) => {
          if (new Date(a.activityDate) > new Date(b.activityDate)) {
            return -1;
          }
          if (new Date(a.activityDate) < new Date(b.activityDate)) {
            return 1;
          }
          return 0;
        })
      )
    );
  }

  getFilters(): ConfigBaseFilter<ActivityInternal>[] {
    return [
      new ActivityInternalStatusFilter(this.metadataService),
      new ActivityInternalTypeFilter(this.metadataService),
      new ActivityInternalFilter('CUSTOMER_RESPONSIBLE', 'customerResponsible')
    ];
  }

  getSortOptions(): ListSortOption<ActivityInternal>[] {
    // TODO: implement
    return [];
  }

  getBatchSizes(): number[] {
    return [24];
  }

  getLayouts(): ListLayout<ActivityInternal>[] {
    return [
      {
        getIconClass: () => '',
        getListItemComponent: () => ActivityInternalTableComponent,
        getListLayoutComponent: () => ListLayoutMultipleComponent,
        getName: () => 'Order list'
      }
    ];
  }

  getSearchTerms(item: ActivityInternal): string[] {
    return [item.customerId, item.customerName];
  }

  getItemId(item: ActivityInternal): string {
    return item.id;
  }

  getNothingFoundTranslateTag() {
    return 'NOTHING_FOUND';
  }

  getSelectionActions(): Observable<SelectionAction<ActivityInternal>[]> {
    return this.documentTemplatesService
      .getTemplates()
      .pipe(first())
      .pipe(
        map(templates => {
          for (const template of templates) {
            if (template.id === 'DEFAULT_ACTIVITIES_XLSX') {
              return [
                {
                  label: template.id,
                  performAction: (selection: ExportSelection<ActivityInternal>) => {
                    const itemList = Object.values(selection.selectedItems);
                    this.activityInternalExportService
                      .exportExcel(itemList, template.id, {})
                      .pipe(first())
                      .subscribe();
                    return of();
                  }
                }
              ];
            }
          }
          return [];
        })
      );
  }
}
