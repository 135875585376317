import { Component, Inject, Optional } from '@angular/core';
import { CheckoutStepComponent, StripeCheckoutService, StripeService } from 'gung-standard';
import {
  CheckoutSettingsGroup,
  HlDisplayPortalFeaturesService
} from 'projects/hl-display/src/app/services/hl-display-portal-features.service';
import { first, forkJoin } from 'rxjs';
declare var Stripe: any;

@Component({
  selector: 'app-hl-display-stripe-payment-step',
  templateUrl: './hl-display-stripe-payment-step.component.html',
  styleUrl: './hl-display-stripe-payment-step.component.css'
})
export class HlDisplayStripePaymentStepComponent extends CheckoutStepComponent {
  private readonly STRIPE_PUBLIC_KEY = this.environment.stripeApiKey;

  constructor(
    protected stripeCheckoutService: StripeCheckoutService,
    protected stripeService: StripeService,
    protected portalFeatureService: HlDisplayPortalFeaturesService,
    @Optional()
    @Inject('environment')
    protected environment: { [s: string]: any }
  ) {
    super();
  }

  isVisible(): boolean {
    return false;
  }

  ngOnInit() {
    super.ngOnInit();
    this.onNextBtnClicked.pipe(first()).subscribe(() => {
      forkJoin({
        settingsGroup: this.portalFeatureService.getPortalFeatureGroup('orderCheckoutSettingsGroup').pipe(first()),
        stripeEnabled: this.stripeCheckoutService.isEnabled().pipe(first())
      })
        .pipe(first())
        .subscribe(({ settingsGroup, stripeEnabled }) => {
          let checkoutSettings = settingsGroup as CheckoutSettingsGroup;

          // When we don't pay with stripe , we don't need to do anything
          if (
            !stripeEnabled ||
            !this.stripeCheckoutService.shouldPayWithStripe(this.checkout) ||
            !checkoutSettings.cardPaymentEnabled
          ) {
            this.stepDone.emit(this.checkout);
            return;
          }

          this.stripeCheckoutService.decorateCheckout(this.checkout);

          this.stripeService
            .createSession(this.checkout)
            .pipe(first())
            .subscribe((res: any) => {
              const sessionId = res.id;

              const stripe = Stripe(this.STRIPE_PUBLIC_KEY);
              stripe
                .redirectToCheckout({
                  sessionId
                })
                .then(result => {
                  console.error(result.error.message);
                });
            });
        });
    });
  }
}
