import { Injectable } from '@angular/core';
import { Product } from '../../models';
import { Availability, SimplifiedAvailability } from '../../models/availability';
import { CustomerProductPrice, Price } from '../../models/price';

@Injectable({
  providedIn: 'root'
})
export class ProductInputQuantityConfigService {
  constructor() { }

  getAllowManualQtyInputOutsideStepSizes(productId: string, product?: Product, price?: CustomerProductPrice, isAnonymous?: boolean, isUser?: boolean, isSales?: boolean, isAdmin?: boolean, isActuator?: boolean): boolean {
    return false;
  }

  getMinimumOrderQuantity(productId: string, product?: Product, price?: CustomerProductPrice): number {
    return 1;
  }

  getStepAmount(productId: string, product?: Product, price?: CustomerProductPrice, roles?: string[]): number {
    return 1;
  }

  getRecomendedInitOrderQuantity(productId: string, product?: Product, price?: CustomerProductPrice): number {
    return 1;
  }

  showDecimals(productId?: string, product?: Product): number {
    return 0;
  }

  getAllowPurchaseOverAvailability(quantity: number, availability: Availability | SimplifiedAvailability, product: Product, roles: string[]): boolean {
    return true;
  }

  getMaxOrderQuantity(productId?: string, product?: Product) {
    return null;
  }

  disableBuyBtn(product: Product, availability: Availability | SimplifiedAvailability, price?: CustomerProductPrice | Price): boolean {
    return false;
  }

  getMaximumOrderQuantityFromAvailability(previousQty: number, quantity: number, availability: Availability | SimplifiedAvailability, product: Product, roles: string[]): number {
    return previousQty;
  }

}
