<div *ngIf="data; else loading" [class.border]="edit">
  <div class="row">
    <div class="col-12">
      <div
        class="image-container top-image"
        [style.backgroundImage]="'url(' + getImage(data.elements?.topImage?.image) + ')'"
        (click)="edit ? openModal('topImage') : navigateTo(data.elements?.topImage.link)"
      >
        <div class="custom-pages-home-default-image-text image-text text-primary w-100 text-uppercase" translate
            [style.color]="'' + getTextColor(data.elements?.topImage?.text) + ' !important'">
          {{ getText(data.elements?.topImage?.text) }}
        </div>
      </div>
    </div>
  </div>
  <div class="row no-gutters">
    <div class="col-12 col-md-6 col-lg-4">
      <div
        class="image-container"
        [style.backgroundImage]="'url(' + getImage(data.elements?.leftImage?.image) + ')'"
        (click)="edit ? openModal('leftImage') : navigateTo(data.elements?.leftImage?.link)"
      >
        <div class="custom-pages-home-default-image-text image-text text-primary w-100 text-uppercase" translate
            [style.color]="'' + getTextColor(data.elements?.leftImage?.text) + ' !important'" >
          {{ getText(data.elements?.leftImage?.text) }}
        </div>
      </div>
    </div>
    <div class="col-12 col-md-6 col-lg-4">
      <div
        class="image-container"
        [style.backgroundImage]="'url(' + getImage(data.elements?.middleImage?.image) + ')'"
        (click)="edit ? openModal('middleImage') : navigateTo(data.elements?.middleImage.link)"
      >
        <div class="custom-pages-home-default-image-text image-text text-primary w-100 text-uppercase" translate
            [style.color]="'' + getTextColor(data.elements?.middleImage?.text) + ' !important'" >
          {{ getText(data.elements?.middleImage?.text) }}
        </div>
      </div>
    </div>
    <div class="col-12 col-md-6 col-lg-4">
      <div
        class="image-container"
        [style.backgroundImage]="'url(' + getImage(data.elements?.rightImage?.image) + ')'"
        (click)="edit ? openModal('rightImage') : navigateTo(data.elements?.rightImage.link)"
      >
        <div class="custom-pages-home-default-image-text image-text text-primary w-100 text-uppercase" translate
            [style.color]="'' + getTextColor(data.elements?.rightImage?.text) + ' !important'" >
          {{ getText(data.elements?.rightImage?.text) }}
        </div>
      </div>
    </div>
    @for (image of moreImage; track image) {
      <div class="col-12 col-md-6 col-lg-4">
        @if(edit) {
          <button type="button" class="btn btn-outline-danger position-absolute mt-2 ml-2" (click)="$event.preventDefault(); $event.stopPropagation(); removeImage(image)">
            <i class="fa-solid fa-circle-minus fa-2x cursor-pointer"></i>
          </button>
        }
        <div
          class="image-container"
          [style.backgroundImage]="'url(' + getImage(data.elements?.[image]?.image) + ')'"
          (click)="edit ? openModal(image) : navigateTo(data.elements?.[image].link)"
        >
          <div class="custom-pages-home-default-image-text image-text text-primary w-100 text-uppercase" translate
              [style.color]="'' + getTextColor(data.elements?.[image]?.text) + ' !important'" >
            {{ getText(data.elements?.[image]?.text) }}
          </div>
        </div>
      </div>
    }
    
    @if (edit) {
      <div class="col-12">
        <button type="button" class="btn btn-outline-primary w-100" (click)="addImage()">
          <i class="fa-solid fa-plus"></i>
        </button>

      </div>
    }
  </div>
</div>

<ng-template #loading translate> LOADING </ng-template>
