import { AfterViewInit, Component, Inject, Input, OnDestroy, OnInit, Optional } from '@angular/core';
import { CheckoutObject } from '../../models/checkout';
import { CheckoutStepComponent } from '../checkout/steps/base/checkout-step.component';
import { first, forkJoin } from 'rxjs';
import { StripeCheckoutService } from '../../services/stripe-checkout/stripe-checkout.service';
import { StripeService } from '../../services/stripe/stripe.service';
import { StripeConfigService } from '../../services/stripe/stripe-config.service';
declare var Stripe: any;

@Component({
  selector: 'lib-stripe-payment-step',
  templateUrl: './stripe-payment-step.component.html',
  styleUrls: ['./stripe-payment-step.component.css']
})
export class StripePaymentStepComponent extends CheckoutStepComponent implements OnInit, OnDestroy {
  @Input()
  public checkout: CheckoutObject;

  private stripe_checkout;

  constructor(
    protected stripeCheckoutService: StripeCheckoutService,
    protected stripeService: StripeService,
    protected stripeConfigService: StripeConfigService
  ) {
    super();
  }

  public isExternalPaymentStep(): boolean {
    return true;
  }

  ngOnInit() {
    super.ngOnInit();

    this.stripeCheckoutService.decorateCheckout(this.checkout);

    forkJoin({
      isEnabled: this.stripeCheckoutService.isEnabled().pipe(first()),
      session: this.stripeService.createSession(this.checkout).pipe(first()),
      publicKey: this.stripeConfigService.getStripePublicKey().pipe(first())
    })
      .pipe(first())
      .subscribe(({ isEnabled, session, publicKey }) => {
        // When we don't pay with stripe, we don't need to do anything
        if (!isEnabled || !this.stripeCheckoutService.shouldPayWithStripe(this.checkout)) {
          this.stepDone.emit(this.checkout);
          return;
        }

        const client_secret = session.client_secret;

        const stripe = Stripe(publicKey);

        stripe
          .initEmbeddedCheckout({
            clientSecret: client_secret
          })
          .then(result => {
            this.stripe_checkout = result;
            this.stripe_checkout.mount('#checkout');
          });
      });
  }

  ngOnDestroy(): void {
    if (this.stripe_checkout) {
      this.stripe_checkout.destroy();
    }
  }
}
