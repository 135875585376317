import {
  Component,
  OnInit,
  ViewChild,
  ComponentFactoryResolver,
  Type,
  OnDestroy,
  AfterViewInit,
  ChangeDetectorRef,
  ViewContainerRef
} from '@angular/core';
import { CheckoutConfigService } from '../../../../services/checkout-config/checkout-config.service';
import { CustomHostDirective } from 'gung-list';
import { CheckoutStepComponent } from '../../steps/base/checkout-step.component';
import { CheckoutObject, CheckoutStepError } from '../../../../models/checkout';
import { BehaviorSubject, Subject, Subscription, pipe } from 'rxjs';
import { ButtonsComponent } from '../../buttons/buttons.component';
import { StepIndicatorComponent } from '../../../step-indicator/step-indicator.component';

import { switchMap, first } from 'rxjs';
import { CartService } from '../../../../services/cart/cart.service';
import { OrderSubmitService } from '../../../../services/order-submit.service';
import { DiscountCodeV2 } from '../../../../services/discount-codes-v2/discount-codes-v2.service';
import { GungModalService } from '../../../../services/gung-modal/gung-modal.service';
import { CartKeyService } from '../../../../services/cart-key/cart-key.service';
import { CartListCheckoutConfigService } from '../../../../services/cart-list-checkout-config/cart-list-checkout-config.service';
import { gungSetPropertyOfObject } from 'gung-common';

@Component({
  selector: 'lib-checkout-wrapper',
  templateUrl: './checkout-wrapper.component.html',
  styleUrls: ['./checkout-wrapper.component.css']
})
export class CheckoutWrapperComponent implements AfterViewInit, OnDestroy {
  public error?: CheckoutStepError;

  @ViewChild(CustomHostDirective)
  protected viewChild: CustomHostDirective;

  @ViewChild('indicator', { read: ViewContainerRef })
  protected indicator: ViewContainerRef;

  @ViewChild('btns', { read: ViewContainerRef })
  protected btns: ViewContainerRef;

  protected currentIndex = 0;
  protected visibleIndex = 0;

  protected steps: Type<CheckoutStepComponent>[];

  protected stepIndexVisible: boolean[];

  public get showLoading(): boolean {
    return this.submitting || !this.stepIndexVisible || !this.stepIndexVisible[this.currentIndex];
  }

  public get isSubmitting(): boolean {
    return this.submitting;
  }

  public get isOfferChannel(): boolean {
    return !!this.currentCheckout?.targetOrderChannel && this.currentCheckout.targetOrderChannel === 'GUNG_WEB_OFFER'
  }

  public sideButtons = true;

  protected submitting = false;
  public orderSubmitError: { show: boolean; error?: any } = { show: false };

  private currentStepDoneSubscription?: Subscription;
  private currentStepErrorSubscription?: Subscription;

  protected currentCheckout: CheckoutObject;

  protected nextStepSubject = new Subject<void | any>();

  public enablePreviousBtnClickedHandler: boolean = true;
  protected prevStepSubject: Subject<void | any> = new Subject<void | any>();

  protected renderedButtons: ButtonsComponent;

  protected renderedIndicator: StepIndicatorComponent;

  protected discountCode: DiscountCodeV2;

  get index(): number {
    return this.currentIndex;
  }

  get nextBtnText(): string {
    return 'next';
  }

  get prevBtnText(): string {
    return 'previous';
  }

  protected disableNextButtonSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(
    protected orderSubmitService: OrderSubmitService,
    protected checkoutConfig: CheckoutConfigService,
    protected componentFactoryResolver: ComponentFactoryResolver,
    protected cartService: CartService,
    protected changeDetectorRef: ChangeDetectorRef,
    protected gungModalService: GungModalService,
    protected cartKeyService: CartKeyService,
    protected cartListCheckoutConfigService: CartListCheckoutConfigService
  ) { }

  // EVENT HANDLERS
  nextBtnClicked(nextInfo?: any) {
    if (nextInfo) {
      this.nextStepSubject.next(nextInfo);
    } else {
      this.nextStepSubject.next(undefined);
    }
  }

  previousBtnClickedHandler(nextInfo?: any) {
    if (nextInfo) {
      this.prevStepSubject.next(nextInfo);
    } else {
      this.prevStepSubject.next(undefined);
    }
  }

  previousBtnClicked() {
    const slicedSteps = this.steps.slice(0, this.currentIndex);
    const filteredSteps = slicedSteps.filter(step => step.prototype.isVisible());
    const lastStep = filteredSteps[filteredSteps.length - 1];
    this.currentIndex = this.steps.indexOf(lastStep);
    this.visibleIndex--;
    this.nextStepSubject.complete();
    this.prevStepSubject?.complete();
    this.renderCurrentStep();
    this.renderDynamicComponents();
  }

  ngOnDestroy(): void {
    this.unsubscribe();
  }

  ngAfterViewInit(): void {
    this.checkoutConfig.getCheckoutSteps().subscribe(steps => {
      this.steps = steps;
      this.stepIndexVisible = this.steps.map(s => s.prototype.isVisible());
      this.renderDynamicComponents();
      this.renderCurrentStep();
    });
  }

  protected renderDynamicComponents() {
    // render the indicator
    const indicatorFactory = this.componentFactoryResolver.resolveComponentFactory(
      this.checkoutConfig.getStepIndicator()
    );
    this.indicator.clear();
    this.renderedIndicator = this.indicator.createComponent(indicatorFactory).instance;
    this.renderedIndicator.currentIndex = this.visibleIndex;
    this.renderedIndicator.stepIndexVisible = this.stepIndexVisible;
    this.renderedIndicator.steps = this.steps;

    // render the buttons
    this.sideButtons = this.checkoutConfig.sideButtons;
    const buttonsFactory = this.componentFactoryResolver.resolveComponentFactory(
      this.checkoutConfig.getButtonsComponent()
    );
    this.btns.clear();
    this.renderedButtons = this.btns.createComponent(buttonsFactory).instance;
    this.renderedButtons.enableDiscount = this.checkoutConfig.enableDiscount || false;
    this.renderedButtons.allSteps = this.steps.filter(step => step.prototype.isVisible());
    this.renderedButtons.currentStep = this.steps[this.currentIndex];
    this.renderedButtons.createOffers = this.checkoutConfig.enableOffers;
    this.renderedButtons.createOffer.subscribe(_ => this.createOffer(_));
    this.renderedButtons.nextClicked.subscribe(_ => this.nextBtnClicked(_));
    this.renderedButtons.prevClicked.subscribe(_ => !this.enablePreviousBtnClickedHandler ? this.previousBtnClicked() : this.previousBtnClickedHandler(_));
    this.renderedButtons.applyDiscountCode.subscribe(discountCode => this.applyDiscountCode(discountCode));
    this.renderedButtons.discountCode = this.currentCheckout?.extra?._discountCode || this.discountCode || undefined;
    this.renderedButtons.updateBtnTexts(this.currentCheckout);
    this.renderedButtons.disableNextButtonObservable = this.disableNextButtonSubject.asObservable();

    this.changeDetectorRef.detectChanges();
  }

  applyDiscountCode(discountCode: DiscountCodeV2) {
    if (discountCode.orderRowDiscount) {
      const discountAmount = discountCode.discountAmount;
      this.cartService.getCurrentCart().pipe(first()).subscribe(cart => {
        console.log('cart', cart);
        const bulkCart: {
          productId: string;
          extra: { [s: string]: any };
          targetStockId?: string;
          productPartialId?: string;
          replace?: boolean;
        }[] = [];
        cart.forEach(({ productId, targetStockId, productPartialId }) => {
          const targetDiscount = this.cartListCheckoutConfigService.transformDiscountToERP(discountAmount);
          const fields = this.cartListCheckoutConfigService.changeDiscountField.split('.');
          const extra = {};
          gungSetPropertyOfObject(extra, fields.join('.'), (targetDiscount || targetDiscount === 0) ? targetDiscount : null, true);

          bulkCart.push({
            productId,
            targetStockId,
            productPartialId,
            replace: false,
            extra
          })
        })
        this.cartService.bulkSetExtra(bulkCart);
      })
    }
    this.discountCode = discountCode;
  }

  protected unsubscribe() {
    if (this.currentStepDoneSubscription) {
      this.currentStepDoneSubscription.unsubscribe();
    }
    if (this.currentStepErrorSubscription) {
      this.currentStepErrorSubscription.unsubscribe();
    }
    if (this.nextStepSubject) {
      this.nextStepSubject.complete();
    }
    this.prevStepSubject?.complete();
  }

  protected nextStep() {
    this.currentIndex++;
    if (this.currentIndex === this.steps.length) {
      this.submitOrder();
      return;
    }
    // render the next component
    this.renderCurrentStep();
    if (this.steps[this.currentIndex].prototype.isVisible()) {
      this.updateDynamicComponents();
    }
  }

  createOffer(nextInfo?: any) {
    this.currentIndex++;
    this.currentCheckout.targetOrderChannel = 'GUNG_WEB_OFFER';
    this.submitting = true;
    this.orderSubmitError.show = false;
    this.checkoutConfig.sumbitOffer(this.currentCheckout, nextInfo).subscribe({
      next: (response) => {
        this.cartKeyService
          .getCurrentCartKey()
          .pipe(first())
          .subscribe(key => {
            localStorage.removeItem(key);
            this.cartService.clearCart();
            this.checkoutConfig.onOrderSubmitted(response, true);
          });

      },
      error: (e) => {
        this.checkoutConfig
          .getShowOrderSubmitError()
          .pipe(first())
          .subscribe(show => {
            if (!!show) {
              this.orderSubmitError.error = e.error;
              this.orderSubmitError.show = !!show;
            }
            this.submitting = false;
          });
        console.error('ERROR POSTING OFFER', e.error);
      }
    })
  }

  protected submitOrder() {
    if (this.currentCheckout.rows.length === 0) {
      // Empty rows card
      this.gungModalService
        .openConfirmYesNoModal('ERROR', 'ERROR_SUBMIT_ORDER_EMPTY_CART', { size: 'sm' }, 'OK', null)
        .then();
      this.previousBtnClicked();
      return;
    }
    this.submitting = true;
    this.orderSubmitError.show = false;

    this.checkoutConfig
      .getSubmitUrl(this.currentCheckout)
      .pipe(switchMap(url => this.orderSubmitService.postOrder(url, this.currentCheckout)))
      .subscribe({
        next: (response) => {
          this.cartKeyService
            .getCurrentCartKey()
            .pipe(first())
            .subscribe(key => {
              localStorage.removeItem(key);
              this.cartService.clearCart();
              this.checkoutConfig.onOrderSubmitted(response);
            });
        },
        error: (err) => {
          this.checkoutConfig
            .getShowOrderSubmitError()
            .pipe(first())
            .subscribe(show => {
              if (!!show) {
                this.orderSubmitError.error = err.error;
                this.orderSubmitError.show = !!show;
              }
              this.submitting = false;
            });
          console.error('ERROR POSTING ORDER', err.error);
        },
      }
      );
  }

  private updateDynamicComponents() {
    // TODO update the buttons and the stepper
    this.changeDetectorRef.detectChanges();
    this.renderDynamicComponents();
  }
  protected displayError(checkoutError: CheckoutStepError) {
    this.error = checkoutError;
  }

  protected renderCurrentStep(): void {
    this.unsubscribe();

    const componentToRender: Type<CheckoutStepComponent> = this.steps[this.currentIndex];
    const factory = this.componentFactoryResolver.resolveComponentFactory(componentToRender);
    const containerRef = this.viewChild.viewContainerRef;
    containerRef.clear();
    const componentRef = containerRef.createComponent(factory);
    const typedComponent = componentRef.instance as CheckoutStepComponent;
    // TODO freeze this object ?
    // set up the inputs to the step

    if (this.currentCheckout !== undefined) {
      typedComponent.checkout = JSON.parse(JSON.stringify(this.currentCheckout));
    } else {
      typedComponent.checkout = this.currentCheckout;
    }
    this.nextStepSubject = new Subject<void | any>();
    typedComponent.onNextBtnClicked = this.nextStepSubject.asObservable();

    this.enablePreviousBtnClickedHandler = typedComponent.enablePreviousBtnClickedHandler;
    if (this.enablePreviousBtnClickedHandler) {
      this.prevStepSubject = new Subject<void | any>();
      typedComponent.onPrevBtnClicked = this.prevStepSubject.asObservable();
    }

    // subscribe to the outputs of the step
    typedComponent.stepDone.subscribe(newCheckout => {
      if (typedComponent.isVisible()) {
        this.visibleIndex++;
      }
      this.nextStepSubject.complete();
      this.prevStepSubject?.complete();
      this.currentCheckout = newCheckout;
      if (this.discountCode !== undefined) {
        this.currentCheckout.extra._discountCode = this.discountCode;
      }
      this.nextStep();
    });
    // subscribe to the outputs of the step
    typedComponent.stepPrevious.subscribe(newCheckout => {
      if (newCheckout) {
        this.currentCheckout = newCheckout;
      }
      this.previousBtnClicked();
    });

    typedComponent.stepError.subscribe(err => {
      this.displayError(err);
    });

    typedComponent.disableNextButton.subscribe(disable => {
      this.disableNextButtonSubject.next(disable);
    });

    // trigger lifecycle hooks on the step
    this.changeDetectorRef.detectChanges();
    if (!typedComponent.isVisible()) {
      // automatically trigger next if the step is not visible
      this.nextStepSubject.next(undefined);
    }
  }
}
