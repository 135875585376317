import { Component, OnInit } from '@angular/core';
import { SelectedCustomerService, Customer } from 'gung-standard';
import { environment } from '../../../environments/environment';
import { TranslateService } from '@ngx-translate/core';
import {
  CheckoutSettingsGroup,
  HlDisplayPortalFeaturesService,
  PortalAdditionalInformationGroup
} from '../../services/hl-display-portal-features.service';
import { first, map } from 'rxjs';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
  selectedCustomer: Customer;
  currentLang = this.translate.currentLang;
  isCardPaymentCustomer = false;
  linkedInSuffix = 'hl-display-ab';
  cardPaymentEnabled = false;

  constructor(
    private selectedCustomerService: SelectedCustomerService,
    protected translate: TranslateService,
    protected hlDisplayPortalFeaturesService: HlDisplayPortalFeaturesService
  ) {
    this.translate.onLangChange.subscribe(() => {
      this.currentLang = this.translate.currentLang;
    });
  }

  ngOnInit() {
    this.hlDisplayPortalFeaturesService
      .getPortalFeatureGroup('portalAdditionalInformationGroup')
      .pipe(
        first(),
        map(informationSettingsGroup => informationSettingsGroup as PortalAdditionalInformationGroup)
      )
      .subscribe(informationSettingsGroup => {
        this.linkedInSuffix = informationSettingsGroup.linkedInUrlSuffix || this.linkedInSuffix;
      });

    this.hlDisplayPortalFeaturesService
      .getPortalFeatureGroup('orderCheckoutSettingsGroup')
      .pipe(
        first(),
        map(checkoutSettingsGroup => checkoutSettingsGroup as CheckoutSettingsGroup)
      )
      .subscribe(checkoutSettingsGroup => {
        this.cardPaymentEnabled = checkoutSettingsGroup.cardPaymentEnabled;
      });

    this.selectedCustomerService.getSelectedCustomer().subscribe(cust => {
      this.selectedCustomer = cust;

      if (!!this.selectedCustomer && this.selectedCustomer.extra && !!this.selectedCustomer.extra.kus) {
        this.isCardPaymentCustomer = this.selectedCustomer.extra.kus.betkod === '99';
      }
    });
  }
}
