import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FilterListComponent } from 'gung-list';
import { OrderServicesTicket } from '../../../services/order-services/order-services.service';
import { ReturnWarrantyListConfigService } from '../../../services/return-warranty-list-config/return-warranty-list-config.service';

@Component({
  selector: 'lib-return-warranty-list',
  template: '<lib-filter-list #list [configService]="returnWarrantyListConfigService"></lib-filter-list>',
  styles: []
})
export class ReturnWarrantyListComponent implements OnInit, OnDestroy {
  @Input()
  customerId?: string;

  @Input() 
  topFilter?: boolean;

  prevTopFilters: boolean; 

  @ViewChild('list', { static: false }) list: FilterListComponent<OrderServicesTicket>;

  constructor(public returnWarrantyListConfigService: ReturnWarrantyListConfigService) {
  }
  
  ngOnInit() {

    this.prevTopFilters = this.returnWarrantyListConfigService.topFilter;
    if(this.topFilter){
      this.returnWarrantyListConfigService.topFilter = this.topFilter;
    }
    this.returnWarrantyListConfigService.setCustomerId(this.customerId);
  }

  ngOnDestroy(): void {
    this.returnWarrantyListConfigService.topFilter = this.prevTopFilters;
  }

}
