import { Component, EventEmitter, Output, Input } from '@angular/core';
import { takeUntil } from 'rxjs';
import { BuyBtnComponent } from '../../../buy-btn/buy-btn.component';

@Component({
  selector: 'lib-product-matrix-input-element',
  templateUrl: './product-matrix-input-element.component.html',
  styleUrls: ['./product-matrix-input-element.component.css']
})
export class ProductMatrixInputElementComponent extends BuyBtnComponent {
  @Input()
  showButtons?: boolean;

  @Input()
  isAvailability?: boolean;

  @Output()
  quantityUpdated = new EventEmitter<string>();

  @Output()
  selectedId = new EventEmitter<string>();

  disabled = true;

  decimals: number;

  @Output()
  focusNextOutput = new EventEmitter<string>();

  initQtySubscription(): void {
    // as the parent set the intial quantity to zero
    // make it null in case it is zero
    this.cartService
      .getProductQty(this.id, this.productPartialId)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(qty => {
        const isInitialFetch = this.currentQty === undefined;

        if (qty) {
          this.currentQty = qty;
          this.previousQty = qty;
        } else {
          this.currentQty = null;
        }

        // AK 2022-05-11: Do not emit event for initial quantityFetch since this is technically not an update of the quantity. For optimizations in checkout
        if (!isInitialFetch) {
          this.quantityUpdated.emit(this.id);
        }
      });

    // Check if have STOCK to order
    this.disabled = (this.availability && this.availability.maxFutureStock && this.availability.maxFutureStock === 0) || this.productInputQuantityConfigService.disableBuyBtn(this.product, this.availability);
  }

  updateCart(quantity: number): void {
    this.currentQty = quantity;
    this.set(this.currentQty);
  }

  setSelectedId() {
    this.selectedId.emit(this.id);
  }

  focusNext(id) {
    this.focusNextOutput.emit(id);
  }
}
