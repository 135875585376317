import { Injectable, Type } from '@angular/core';
import { ListItemRendererComponent } from 'gung-list';
import { BarcodeProductDetailsComponent } from '../../components/barcode-scanner/barcode-product-details/barcode-product-details.component';
import { BarcodeQuickCheckoutComponent } from '../../components/barcode-scanner/barcode-quick-checkout/barcode-quick-checkout.component';
import { BarcodeScannerComponent } from '../../components/barcode-scanner/barcode-scanner.component';
import { ProductsInCartComponent } from '../../components/barcode-scanner/products-in-cart/products-in-cart.component';
import { ScannerComponent } from '../../components/barcode-scanner/scanner/scanner.component';
import { BuyBtnMatrixComponent } from '../../components/buy-btn-matrix/buy-btn-matrix.component';
import { BuyBtnComponent } from '../../components/buy-btn/buy-btn.component';
import { ConceptDetailV2ProductListViewComponent } from '../../components/concept-detail-v2-product-list-view/concept-detail-v2-product-list-view.component';
import { ConceptDetailV2TopDetailsComponent } from '../../components/concept-detail-v2-top-details/concept-detail-v2-top-details.component';
import { EdiDispatchCardListComponent } from '../../components/edi/edi-dispatch-card-list/edi-dispatch-card-list.component';
import { EdiInvoiceCardListComponent } from '../../components/edi/edi-invoice-card-list/edi-invoice-card-list.component';
import { EdiOrderCardListComponent } from '../../components/edi/edi-order-card-list/edi-order-card-list.component';
import { EdiOrderResponseCardListComponent } from '../../components/edi/edi-order-response-card-list/edi-order-response-card-list.component';
import { GungViewer3dComponent } from '../../components/gung-viewer3d/gung-viewer3d.component';
import { FashionLoginComponent } from '../../components/login/fashion-login/fashion-login.component';
import { LoginComponent } from '../../components/login/login.component';
import { NavbarLoginComponent } from '../../components/login/navbar-login/navbar-login.component';
import { ProductDetailV2Component } from '../../components/product-detail-v2/product-detail-v2.component';
import { ProductDetailComponent } from '../../components/product-detail/product-detail.component';
import { ProductInlineSearchCardComponent } from '../../components/product-inline-search-card/product-inline-search-card.component';
import { ProductListV2Component } from '../../components/product-list-v2/product-list-v2.component';
import { ProductListComponent } from '../../components/product-list/product-list.component';
import { RequestAccountComponent } from '../../components/request-account/request-account.component';
import { TranslationManagementComponent } from '../../components/translation-management/translation-management.component';
import { EdiDespatchAdvice, EdiInvoice, EdiOrder, EdiRespondeOrder } from '../../models/edi';
import { Product } from '../../models/product';
import { Assortment } from '../assortment.service';
import { PriceService } from '../price/price.service';
import { EanNumbers } from '../ean-numbers/ean-numbers.service';
import { EanRepositoryCardListComponent } from '../../components/edi/ean-repository-card-list/ean-repository-card-list.component';
import { CartRow } from '../../state/cart/types';
import { EdiIncomingDespatchCardListComponent } from '../../components/edi/edi-incoming-despatch-card-list/edi-incoming-despatch-card-list.component';
import { GlobalSearchCardComponent } from '../../components/global-search-card/global-search-card.component';
import { PriceComponent } from '../../components/price/price.component';
import { ResetPasswordComponent } from '../../components/reset-password/reset-password.component';
import { FashionResetPasswordComponent } from '../../components/reset-password/fashion-reset-password/fashion-reset-password.component';
import { GungNavbarCartComponent } from '../../components/gung-main-layout/gung-navbar-cart/gung-navbar-cart.component';
import { FooterComponent } from '../../components/footer/footer.component';

@Injectable({
  providedIn: 'root'
})
export class BaseViewConfigService {
  defaultProductDetail: any = ProductDetailComponent;
  defaultProductList: any = ProductListComponent;
  loginType: 'FashionLogin' | 'NavbarLogin' | string;

  productViewType: ProductViewType = ProductViewType.defaultView;
  public priceService: PriceService;

  navbarcartStyle: 'CartShopping' | 'BagShopping' | string = 'CartShopping';

  constructor(protected priceServiceInput: PriceService) {
    this.priceService = priceServiceInput;
  }

  /**
   * Override for adding a customer specific detail component.
   * AssortmentTreeView handles the product detail elsewhere.
   * @returns The ProductDetailPageComponent
   */
  getProductDetailComponent(): any {
    if (this.productViewType === ProductViewType.defaultView) {
      return this.defaultProductDetail;
    } else {
      return ProductDetailV2Component;
    }
  }

  getConceptDetailTopDetailsComponent(): any {
    return ConceptDetailV2TopDetailsComponent;
  }

  getConceptDetailV2ProductListViewComponent(): Type<ConceptDetailV2ProductListViewComponent> {
    return ConceptDetailV2ProductListViewComponent;
  }

  /**
   * Override for adding a customer specific product list component
   * @returns The ProductListComponent
   */
  getProductListComponent(): any {
    if (this.productViewType === ProductViewType.defaultView) {
      return this.defaultProductList;
    } else {
      return ProductListV2Component;
    }
  }

  getBuyButtonComponent(product: Product, checkoutCart?: CartRow | boolean): any {
    if (product?.productType === 'multiDimension') {
      return BuyBtnMatrixComponent;
    } else {
      return BuyBtnComponent;
    }
  }

  /**
   * EdiOrderListConfigService
   */
  getEdiOrderListComponent(): Type<ListItemRendererComponent<EdiOrder[]>> {
    return EdiOrderCardListComponent;
  }

  /**
   * EdiOrderResponseCardListComponent
   */
  getEdiOrderResponseCardListComponent(): Type<ListItemRendererComponent<EdiRespondeOrder[]>> {
    return EdiOrderResponseCardListComponent;
  }

  /**
   * EdiInvoiceCardListComponent
   */
  getEdiInvoiceCardListComponent(): Type<ListItemRendererComponent<EdiInvoice[]>> {
    return EdiInvoiceCardListComponent;
  }

  /**
   * EdiDispatchCardListComponent
   */
  getEdiDispatchCardListComponent(): Type<ListItemRendererComponent<EdiDespatchAdvice[]>> {
    return EdiDispatchCardListComponent;
  }

  /**
   * EdiIncomingDispatchCardListComponent
   */
  getEdiIncomingDespatchCardListComponent(): Type<ListItemRendererComponent<EdiDespatchAdvice[]>> {
    return EdiIncomingDespatchCardListComponent;
  }
  /**
   * EanNumbersListComponent
   */
  getEanNumbersListComponent(): Type<ListItemRendererComponent<EanNumbers[]>> {
    return EanRepositoryCardListComponent;
  }

  /**
   * Set the login page
   * @returns Type<LoginComponent>
   */
  getLoginComponent(loginType = this.loginType): Type<LoginComponent> {
    switch (loginType) {
      case 'FashionLogin':
        return FashionLoginComponent;
      case 'NavbarLogin':
        return NavbarLoginComponent;
      default:
        return LoginComponent;
    }
  }

  /**
   * Set the reset password page
   * @returns Type<ResetPasswordComponent>
   */
  getResetPasswordComponent(resetPasswordType: string = ''): Type<ResetPasswordComponent> {
    switch (resetPasswordType) {
      case 'fashion':
        return FashionResetPasswordComponent;
      default:
        return ResetPasswordComponent;
    }
  }

  /**
   * ProductInlineSearchCardComponent
   * @returns Type<ProductInlineSearchCardComponent>
   */
  getProductInlineSearchCardComponent(): Type<ProductInlineSearchCardComponent> {
    return ProductInlineSearchCardComponent;
  }

  /**
   * GlobalSearchCardComponent
   * @returns Type<GlobalSearchCardComponent>
   */
  getGlobalSearchCardComponent(): Type<GlobalSearchCardComponent> {
    return GlobalSearchCardComponent;
  }

  /**
   * BarcodeProductDetailsComponent
   * @returns Type<BarcodeProductDetailsComponent>
   */
  getBarcodeProductDetailsComponent(): Type<BarcodeProductDetailsComponent> {
    return BarcodeProductDetailsComponent;
  }

  /**
   * BarcodeQuickCheckoutComponent
   * @returns Type<BarcodeQuickCheckoutComponent>
   */
  getBarcodeQuickCheckoutComponent(): Type<BarcodeQuickCheckoutComponent> {
    return BarcodeQuickCheckoutComponent;
  }

  /**
   * ProductsInCartComponent
   * @returns Type<ProductsInCartComponent>
   */
  getProductsInCartComponent(): Type<ProductsInCartComponent> {
    return ProductsInCartComponent;
  }

  /**
   * ScannerComponent
   * @returns Type<ScannerComponent>
   */
  getScannerComponent(): Type<ScannerComponent> {
    return ScannerComponent;
  }

  /**
   * BarcodeScannerComponent
   * @returns Type<BarcodeScannerComponent>
   */
  getBarcodeScannerComponent(): Type<BarcodeScannerComponent> {
    return BarcodeScannerComponent;
  }

  /**
   * TranslationManagementComponent
   * @returns Type<TranslationManagementComponent>
   */
  getTranslationManagementComponent(): Type<TranslationManagementComponent> {
    return TranslationManagementComponent;
  }

  getViewer3d(): Type<GungViewer3dComponent> {
    return GungViewer3dComponent;
  }

  getShowProductsColumn() {
    return true;
  }

  getShowCategoriesColumn() {
    return true;
  }

  /**
   * RequestAccountComponent
   * @returns Type<RequestAccountComponent>
   */
  getRequestAccountComponent(): Type<RequestAccountComponent> {
    return RequestAccountComponent;
  }

  getPriceComponent(): Type<PriceComponent> {
    return PriceComponent;
  }

  getNavbarCartBox(): Type<GungNavbarCartComponent> {
    return GungNavbarCartComponent;
  }

  getFooterComponent(): Type<FooterComponent> {
    return FooterComponent;
  }
}

@Injectable({
  providedIn: 'root'
})
export class CurrentAssortmentService {
  private _currentAssortment: Assortment;

  constructor() {}

  public get currentAssortment(): Assortment {
    return this._currentAssortment;
  }
  public set currentAssortment(value: Assortment) {
    this._currentAssortment = value;
  }
}

export enum ProductViewType {
  defaultView,
  productV2, // has pim data
  assortmentTreeView // has pim data and assortment tree structure
}
