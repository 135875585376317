import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { format, parse } from 'date-fns';
import { RenderFilter } from '../types';
import { DateUtilService } from 'gung-common';
import { FilterListDateUtilService } from '../services/filter-list-date-util.service';

@Component({
  selector: 'lib-filter-date-range',
  templateUrl: './filter-date-range.component.html',
  styleUrls: ['./filter-date-range.component.css']
})
export class FilterDateRangeComponent implements OnInit, OnChanges {
  minDate: NgbDateStruct;
  maxDate: NgbDateStruct;
  minPossibleDate: NgbDateStruct;
  maxPossibleDate: NgbDateStruct;
  showTodayDate: boolean = false;
  typeInDates: boolean = false;

  @Input()
  public renderFilter: RenderFilter;

  @Input()
  public changeMax?: string;

  @Input()
  public changeMin?: string;

  @Output()
  selectDateRange = new EventEmitter<string[]>();

  public firstDayOfWeek: number = this.filterListDateUtilService.getFirstDayOfWeekRealNumber(true);

  constructor(
    protected activatedRoute: ActivatedRoute,
    protected filterListDateUtilService: FilterListDateUtilService,
    protected dateUtilService: DateUtilService
  ) { }

  ngOnInit(): void {
    const min = Math.min(
      ...this.renderFilter.valueList
        .filter(v => v.valueId && v.valueId.trim() !== '' && !isNaN(v.valueId as any))
        .map(v => parseFloat(v.valueId))
    ).toString();
    const minDate = this.dateUtilService.parseDate(min.padStart(6, '0'), 'yyMMdd');
    this.minPossibleDate = { year: minDate.getFullYear(), month: minDate.getMonth() + 1, day: minDate.getDate() };
    this.minDate = this.minPossibleDate;
    const max = Math.max(
      ...this.renderFilter.valueList
        .filter(v => v.valueId && v.valueId.trim() !== '' && !isNaN(v.valueId as any))
        .map(v => parseFloat(v.valueId))
    ).toString();
    const maxDate = this.dateUtilService.parseDate(max.padStart(6, '0'), 'yyMMdd');
    this.maxPossibleDate = { year: maxDate.getFullYear(), month: maxDate.getMonth() + 1, day: maxDate.getDate() };
    this.maxDate = this.maxPossibleDate;
    /* remove error from console
    this.renderFilter.minValue = Number(min);
    this.renderFilter.maxValue = Number(max); */
    this.showTodayDate = this.renderFilter.showTodayDate;
    this.typeInDates = this.renderFilter.typeInDates;
    this.activatedRoute.queryParams.subscribe((params: Params) => {
      if (params.filters && params?.filters?.includes(this.renderFilter.name)) {
        const filters = params.filters as string;
        const index = filters.indexOf(filters);
        const indexValues = index + this.renderFilter.name.length;
        let values = filters.substring(indexValues, filters.length);
        if (values.indexOf('__|__') > -1) {
          values = values.substring(0, values.indexOf('__|__'));
        }
        const urlMin = values.substring(values.indexOf('__:__') + 5, values.indexOf('_____'));
        const minDateUrl = parse(urlMin, 'yyMMdd', new Date());
        this.minDate = { year: minDateUrl.getFullYear(), month: minDateUrl.getMonth() + 1, day: minDateUrl.getDate() };
        const urlMax = values.substring(values.indexOf('_____') + 5, values.length);
        const maxDateUrl = parse(urlMax, 'yyMMdd', new Date());
        this.maxDate = { year: maxDateUrl.getFullYear(), month: maxDateUrl.getMonth() + 1, day: maxDateUrl.getDate() };
      }
    });
  }

  minDateSelect(value: NgbDateStruct) {
    const formatToBdDateMin = format(new Date(value.year, value.month - 1, value.day), 'yyMMdd');
    const formatToBdDateMax = format(new Date(this.maxDate.year, this.maxDate.month - 1, this.maxDate.day), 'yyMMdd');
    this.selectDateRange.emit([formatToBdDateMin, formatToBdDateMax]);
  }

  maxDateSelect(value: NgbDateStruct) {
    const formatToBdDateMax = format(new Date(value.year, value.month - 1, value.day), 'yyMMdd');
    const formatToBdDateMin = format(new Date(this.minDate.year, this.minDate.month - 1, this.minDate.day), 'yyMMdd');
    this.selectDateRange.emit([formatToBdDateMin, formatToBdDateMax]);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!this.changeMax) {
      this.maxDate = this.maxPossibleDate;
    }
    if (!this.changeMin) {
      this.minDate = this.minPossibleDate;
    }
  }

  get today(): NgbDateStruct {
    const currentDate = new Date();
    return {
      year: currentDate.getFullYear(),
      month: currentDate.getMonth() + 1,
      day: currentDate.getDate()
    };
  }

  selectTodayMinDate() {
    this.minDate = this.today;
    this.minDateSelect(this.minDate);
  }

  selectTodayMaxDate() {
    this.maxDate = this.today;
    this.maxDateSelect(this.maxDate);
  }

  onMaxDateInputBlur() {
    if (this.maxDate) {
      const isValid = this.validateDate(this.maxDate); 
      if (isValid) {
        this.maxDateSelect(this.maxDate); 
      } else {
        console.warn('Invalid Date:', this.maxDate);
      }
    }
  }

  onMinDateInputBlur() {
    if (this.minDate) {
      const isValid = this.validateDate(this.minDate); 
      if (isValid) {
        this.minDateSelect(this.minDate); 
      } else {
        console.warn('Invalid Date:', this.minDate);
      }
    }
  }
  
  validateDate(date: any): boolean {
    if (!date || !date.year || !date.month || !date.day) {
      return false;
    }
    const jsDate = new Date(date.year, date.month - 1, date.day); 

    return (
      jsDate.getFullYear() === date.year &&
      jsDate.getMonth() === date.month - 1 &&
      jsDate.getDate() === date.day
    );
  }
}
