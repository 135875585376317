import { inject, Inject, Injectable, Optional, Type } from '@angular/core';
import { Router } from '@angular/router';
import { forkJoin, Observable, of } from 'rxjs';
import { first, map } from 'rxjs';
import { ButtonsComponent } from '../../components/checkout/buttons/buttons.component';
import { SideButtonsComponent } from '../../components/checkout/side-buttons/side-buttons.component';
import { AssignDiscountCodeComponent } from '../../components/checkout/steps/assign-discount-code/assign-discount-code.component';
import { AssignFlowSettingComponent } from '../../components/checkout/steps/assign-flow-setting/assign-flow-setting.component';
import { AssignOrderRowsComponent } from '../../components/checkout/steps/assign-order-rows/assign-order-rows.component';
import { AssignSystemSettingComponent } from '../../components/checkout/steps/assign-system-setting/assign-system-setting.component';
import { AssignUserSettingComponent } from '../../components/checkout/steps/assign-user-setting/assign-user-setting.component';
import { CheckoutStepComponent } from '../../components/checkout/steps/base/checkout-step.component';
import { CartTotalStepComponent } from '../../components/checkout/steps/cart-total-step/cart-total-step.component';
import { InitCheckoutObjectComponent } from '../../components/checkout/steps/init-checkout-object/init-checkout-object.component';
import { RemoveBlockedProductsStepComponent } from '../../components/checkout/steps/remove-blocked-products-step/remove-blocked-products-step.component';
import { StepIndicatorComponent } from '../../components/step-indicator/step-indicator.component';
import { CheckoutObject } from '../../models/checkout';
import { NavigationConfig } from '../../models/navigation-config';
import { SimplifiedOrderContext } from '../../models/simplifiedOrderContext';
import { GungFlowService } from '../gung-flow/gung-flow.service';
import { CheckoutCartListComponent } from '../../components/checkout/steps/checkout-cart-list/checkout-cart-list.component';
import { FeatureActivatedGuardWrapper } from '../../guards/feature-activated/feature-activated.guard';
import { DeliveryLocationsComponent } from '../../components/checkout/steps/delivery-locations/delivery-locations.component';
import { AssignDefaultsComponent } from '../../components/checkout/steps/assign-defaults/assign-defaults.component';
import { SummarizeOrderComponent } from '../../components/checkout/steps/summarize-order/summarize-order.component';
import { AuthService } from '../auth/auth.service';
import { OrderTermsComponent } from '../../components/checkout/steps/order-terms/order-terms.component';

@Injectable({
  providedIn: 'root'
})
export class CheckoutConfigService {
  sideButtons = false;
  enableDiscount = false; // Add input to checkout to apply discount code / welcome code
  enableOffers = this.environment?.enableOffers || false;
  checkoutOnCartList = this.environment?.checkoutOnCartList || false;
  hideCreateQuotation: boolean = false;

  constructor(
    protected router: Router,
    protected gungFlowService: GungFlowService,
    @Optional()
    @Inject('environment')
    protected environment: NavigationConfig,
    protected authService: AuthService
  ) {
    inject(FeatureActivatedGuardWrapper)
      .isActivated('gung-discount-codes', '3.0.17')
      .pipe(first())
      .subscribe(enableDiscount => (this.enableDiscount = enableDiscount));
  }

  getCheckoutSteps(): Observable<Type<CheckoutStepComponent>[]> {
    return forkJoin([this.getPreProcessSteps(), this.getMainProcessSteps(), this.getPostProcessSteps()]).pipe(
      map(([pre, main, post]) => [...pre, ...main, ...post])
    );
  }

  standardMainSteps(): Observable<Type<CheckoutStepComponent>[]> {
    return of([
      this.checkoutOnCartList ? CheckoutCartListComponent : CartTotalStepComponent,
      AssignOrderRowsComponent,
      AssignDiscountCodeComponent
    ]);
  }

  getMainProcessSteps(): Observable<Type<CheckoutStepComponent>[]> {
    return forkJoin({
      steps: this.standardMainSteps().pipe(first()),
      roles: this.authService.getRoles().pipe(first())
    }).pipe(
      map(({ steps, roles }) => {
        const isSalesOrAdmin = roles.includes('SALES') || roles.includes('ADMIN')
        let checkoutMainSteps = [...steps, DeliveryLocationsComponent];
        if (isSalesOrAdmin) {
          checkoutMainSteps.push(OrderTermsComponent);
        }
        checkoutMainSteps.push(SummarizeOrderComponent);
        return checkoutMainSteps;
      })
    );
  }

  getPreProcessSteps(): Observable<Type<CheckoutStepComponent>[]> {
    return this.standardPreSteps().pipe(map(steps => [...steps, AssignDefaultsComponent]));
  }

  standardPreSteps(): Observable<Type<CheckoutStepComponent>[]> {
    return of([
      InitCheckoutObjectComponent,
      AssignSystemSettingComponent,
      AssignFlowSettingComponent,
      AssignUserSettingComponent,
      RemoveBlockedProductsStepComponent
    ]);
  }

  getPostProcessSteps(): Observable<Type<CheckoutStepComponent>[]> {
    return of([]);
  }

  envProduction = this.environment.production;
  getSubmitUrl(checkoutObject: CheckoutObject = null): Observable<string> {
    if (!this.envProduction || window.location.hostname === 'localhost') {
      const send = prompt('Do you really want to send this order? (NO or YES)', 'NO/YES');
      if (!(!!send && send.toUpperCase() === 'YES')) {
        console.error('YOU ARE NOT IN PRODUCTION MODE. checkoutObject:', checkoutObject);
        return of(null);
      }
    }
    if (checkoutObject.targetOrderChannel) {
      return of('json/order/create/' + checkoutObject.targetOrderChannel);
    } else {
      return of('json/order/create');
    }
  }

  sumbitOffer(checkoutObject: CheckoutObject, nextInfo?: any): Observable<SimplifiedOrderContext> {
    return of();
  }

  onOrderSubmitted(context: SimplifiedOrderContext, isOffer = false): void {
    this.gungFlowService
      .getSelectedFlow()
      .pipe(first())
      .subscribe(flow => {
        if (flow.inventory) {
          this.router.navigateByUrl(`/send-counting-transaction/${context.contextId}`);
        }
        if (context.finalized) {
          this.router.navigateByUrl(`/orders/${context.erpOrderIds[0]}`);
          return;
        }
        if (isOffer) {
          this.router.navigateByUrl(`/offer-confirmation/${context.contextId}`);
          return;
        }
        this.router.navigateByUrl(`/order-confirmation/${context.contextId}`);
      });
  }

  getShowOrderSubmitError(): Observable<boolean> {
    return of(true);
  }

  getStepIndicator(): Type<StepIndicatorComponent> {
    return StepIndicatorComponent;
  }

  getButtonsComponent(): Type<ButtonsComponent> {
    if (this.sideButtons) {
      return SideButtonsComponent;
    }

    return ButtonsComponent;
  }

  getBulkOrderERPSteps(): Observable<Type<CheckoutStepComponent>[]> {
    return of([]);
  }
}
