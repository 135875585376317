import { Injectable } from '@angular/core';
import { OrderService, Order, CustomerService, OrderRow, Customer } from 'gung-standard';
import { Observable, Subject, BehaviorSubject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { first } from 'rxjs';
import { JeevesOrderService } from 'gung-standard-jeeves';

@Injectable({
  providedIn: 'root'
})
export class HlDisplayOrderService extends JeevesOrderService {
  private httpClient: HttpClient;
  private quotes: BehaviorSubject<Order[]>;
  private quoteObservable: Observable<Order[]>;

  constructor(http: HttpClient, customerService: CustomerService) {
    super(http, customerService);
    this.httpClient = http;
    /**
     * This seems to be some unused code that causes a bunch of performance issues... - Adam
    
    
    this.getQuotes(true).subscribe(v => {
      this.quotes = new BehaviorSubject<Order[]>(v);
      this.quoteObservable = this.quotes.asObservable();
    });*/
  }

  getOpenOrders(nocache?: boolean): Observable<Order[]> {
    const url = `json/hl-display/open-orders`;
    const headers = {
      maxAge: nocache ? '-1' : '60'
    };
    return this.httpClient.get<Order[]>(url, { headers });
  }

  getQuotes(nocache?: boolean): Observable<Order[]> {
    const url = `json/hl-display/quotes`;
    const headers = {
      maxAge: nocache ? '-1' : '60'
    };
    return this.httpClient.get<Order[]>(url, { headers });
  }

  updateQuotesSubject(): void {
    this.getQuotes(true).subscribe(d => this.quotes.next(d));
  }

  getQuotesFromSubject(): Observable<Order[]> {
    return this.quoteObservable;
  }

  postAddRowToOrder(orderId: string, row: any): Observable<Order> {
    const url = `json/order/${orderId}/row`;
    return this.httpClient.post<Order>(url, row);
  }

  postAppendToOrder(order: any) {
    const url = `json/order/append`;
    return this.httpClient.post<Order[]>(url, order);
  }

  getOrderRows(customerId: string, nocache?: boolean): Observable<OrderRow[]> {
    const url = `json/order-rows/customer/${customerId}`;
    const headers = {
      maxAge: nocache ? '-1' : '300'
    };
    return this.httpClient.get<OrderRow[]>(url, { headers });
  }

  getSelectableInvoiceCustomers(nocache?: boolean): Observable<Customer[]> {
    const url = `json/hl-display/selectable-invoice-customers`;
    const headers = {
      maxAge: nocache ? '-1' : '300'
    };
    return this.httpClient.get<OrderRow[]>(url, { headers });
  }
}
