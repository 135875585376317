import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, UrlSegment } from '@angular/router';
import { ExportDigitalAssetsFolderListConfigService } from '../../services/export-digital-assets-folder-list-config.service';
import { ExportDigitalAssetsListConfigService } from '../../services/export-digital-assets-list-config.service';
import { ExportDigitalAssetsRouterService } from '../../services/export-digital-assets-router.service';
import { ExportDragDigitalAssetsConfigService } from '../../services/export-drag-digital-assets-config.service';
import { ExportDragProductService } from '../../services/export-drag-product.service';
import { PdfDigitalAssetsSearchListConfigService } from '../../services/pdf-digital-assets-search-list-config.service';
import { DigitalAsset, PdfDigitalAssetsService } from '../../services/pdf-digital-assets.service';

@Component({
  selector: 'lib-export-digital-assets-drag-in',
  templateUrl: './export-digital-assets-drag-in.component.html',
  styleUrls: ['./export-digital-assets-drag-in.component.css']
})
export class ExportDigitalAssetsDragInComponent implements OnInit {
  isFolder: boolean;
  selectedDigitalAsset;
  isSearching = false;
  digitalAssetsSearch;
  breadcrumbs;
  digitalAssetDetails = false;
  constructor(
    protected router: Router,
    protected route: ActivatedRoute,
    protected digitalAssetsService: PdfDigitalAssetsService,
    public digitalAssetsListConfigService: ExportDigitalAssetsListConfigService,
    public digitalAssetsFolderListConfigService: ExportDigitalAssetsFolderListConfigService,
    public digitalAssetsSearchListConfigService: PdfDigitalAssetsSearchListConfigService,
    public digitalAssetsConfigService: ExportDragDigitalAssetsConfigService,
    protected exportDigitalAssetsRouterService: ExportDigitalAssetsRouterService,
    protected exportDragProductService: ExportDragProductService
  ) {
    this.exportDigitalAssetsRouterService.currentDigitalAsset.next(null);
  }

  ngOnInit() {
    this.digitalAssetsListConfigService.isManagement = false;
    const segments = null;
    this.exportDigitalAssetsRouterService.getCurrentDigitalAsset().subscribe(currentDigitalAsset => {

      this.isFolder = false;
      this.digitalAssetDetails = false;
      if (!currentDigitalAsset) {
        // First
        
        this.digitalAssetsFolderListConfigService.firstLoad = true;
        this.digitalAssetsService
          .getDigitalAssets()
          .pipe()
          .subscribe(digitalAssets => {
            const filteredDigitalAssets = digitalAssets
              .filter(d => d.extra.type === 'folder')
              .filter(d => !d.extra.parentId || d.extra.parentId === '');
            this.digitalAssetsFolderListConfigService.digitalAssetsObjectsSubject.next(
              filteredDigitalAssets.map(d => d.id)
            );
            this.isFolder = true;
            this.breadcrumbs = this.calculateBreadcrumb(currentDigitalAsset, digitalAssets);
          });
      } else {
        // Show DigitalAsset ot children DigitalAsset
        this.digitalAssetsFolderListConfigService.firstLoad = false;
        this.digitalAssetsService
          .getDigitalAssets()
          .pipe()
          .subscribe(digitalAssets => {
            if (currentDigitalAsset.includes(':digitalasset-')) {
              this.digitalAssetDetails = true;
              currentDigitalAsset = currentDigitalAsset.replace(':digitalasset-', '');
            }
            const digitalAsset = digitalAssets.find(d => d.id === currentDigitalAsset);
            if (!digitalAsset) {
              return;
            }
            if (digitalAsset.extra.type === 'folder') {
              const children = digitalAssets.filter(d => d.extra.parentId === currentDigitalAsset);
              if (digitalAsset.extra.content === 'folder') {
                this.isFolder = true;
                this.digitalAssetsFolderListConfigService.digitalAssetsObjectsSubject.next(children.map(d => d.id));
                this.digitalAssetsSearchListConfigService.digitalAssetsObjectsSubject.next(children.map(d => d.id));
              } else {
                
                this.digitalAssetsListConfigService.digitalAssetsObjectsSubject.next(children.map(d => d.id));
                this.digitalAssetsSearchListConfigService.digitalAssetsObjectsSubject.next(children.map(d => d.id));
              }
            } else {
              this.selectedDigitalAsset = digitalAsset;
              if(digitalAsset.extra.type !== 'folder'){
                this.digitalAssetDetails = true
              }
            }
            this.breadcrumbs = this.calculateBreadcrumb(digitalAsset.id, digitalAssets);
          });
      }
    });
  }

  calculateBreadcrumb(currentId, digitalAssets: DigitalAsset[]) {
    const breadcrumb: { name: string; id: string }[] = [];

    // Create a map of id to object for quick lookup
    const objectMap = new Map(digitalAssets.map(obj => [obj.id, obj]));
  
    let currentObject = objectMap.get(currentId);
  
    // Traverse up the hierarchy to build the breadcrumb
    while (currentObject) {
      breadcrumb.unshift({ name: currentObject.name, id: currentObject.id }); // Add the name and id to the breadcrumb
      const parentId = currentObject.extra.parentId;
      currentObject = parentId ? objectMap.get(parentId) : undefined; // Move to the parent object
    }
  
    breadcrumb.unshift({name: 'DIGITAL_ASSETS', id: null})
    
    return breadcrumb;
    
  }

  digitalAssetGo(id){
    this.exportDigitalAssetsRouterService.currentDigitalAsset.next(id);
  }

  homeDigitalAsset() {
    this.exportDigitalAssetsRouterService.currentDigitalAsset.next(null);
  }

  searchChange(search) {
    if (search) {
      this.isSearching = true;
    } else {
      this.isSearching = false;
    }
    this.digitalAssetsSearchListConfigService.getItems(search);
    this.digitalAssetsSearchListConfigService.searchDigitalAssetsObjectsSubject.next(search);
  }

  addToSheet(image) {
    this.exportDragProductService.setOneDigitalAsset(image);
  }

  onDragStart(event, image) {
    this.exportDragProductService.draggedDigitalAsset = image;
  }
}
