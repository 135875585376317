import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class S3UploadService {
  protected baseUrl = 'import';

  constructor(protected http: HttpClient) {}

  public postImage(image: File, imgPath?: string): Observable<S3Image> {
    const url = `${this.baseUrl}/s3-image`;
    const formData = new FormData();
    formData.append('file', image);
    formData.append('productImage', JSON.stringify(image));
    if (!!imgPath) {
      formData.append('imagePath', imgPath);
    }
    return this.http.post<S3Image>(url, formData);
  }

  public postDocument(document: File): Observable<S3Item> {
    const url = `${this.baseUrl}/s3-document`;
    const formData = new FormData();
    formData.append('file', document);
    formData.append('productDocument', JSON.stringify(document));
    return this.http.post<S3Item>(url, formData);
  }

  public presignedUrlUpload(request: PresignedUrlRequest): Observable<any> {
    const url = `json/s3/presigned-urls/upload`;
    return this.http.post<any>(url, request);
  }

  public uploadFile(file: File, presignedUrl: string): Observable<string> {
    const headers = new HttpHeaders({ 'Content-Type': file.type });
    return this.http
      .put(presignedUrl, file, {
        headers,
        observe: 'response' 
      })
      .pipe(
        map(response => {
          let etag = response.headers.get('Etag');
          etag = this.removeExtraQuotes(etag);
          return etag;
        })
      );
  }

  removeExtraQuotes(value: string): string {
    return value.replace(/^"|"$/g, '');
  }

  public createFileRelation(data: S3FileRelation): Observable<S3FileRelation> {
    const url = `json/document-relations`;
    return this.http.post<S3FileRelation>(url, data);
  }
}

export interface S3FileRelation {
  s3Uri: string;
  s3Etag?: string;
  relationIds: string[];
  documentType: string;
  createdAt?: Date;
  updatedAt?: Date;
  id?: string;
}
export interface PresignedUrlRequest {
  key: string;
  appendCustomerPrefix: boolean;
}

export interface RelationS3 {
  key: string;
  appendCustomerPrefix: boolean;
}

export interface S3Item {
  id: string;
  name: string;
  extra: { [s: string]: any } | null;
  s3Uri: string;
  s3ETag: string;
  description: string;
  category: string;
  i18n: { [language: string]: any };
  pimDimensionIds: string[];
  fullImageUrl?: string;
}

export interface S3Image extends S3Item {
  index: number;
  s3lastModifiedDate: Date;
}
