<div>
  <div class="modal-header">
    <h3>{{ title }}</h3>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body">
    <div class="row">
      <div class="col-12">
        <div [innerHTML]="htmlContent | safeHtml"></div>
      </div>
    </div>
  </div>

  <div *ngIf="!hideFooter" class="modal-footer">
    <button type="button" class="btn btn-sm btn-default btn-block btn-primary" (click)="activeModal.dismiss('ok')">
      {{ btnText | translate | uppercase }}
    </button>
  </div>
</div>
