import { AfterContentChecked, Component, ContentChildren, Directive, EventEmitter, Input, Output, QueryList, TemplateRef, ViewChild, ViewEncapsulation } from '@angular/core';
import { NgbAccordionDirective } from '@ng-bootstrap/ng-bootstrap';


@Directive({ selector: 'ng-template[libGungAccordionHeader]' })
export class GungAccordionHeader {
  constructor(public templateRef: TemplateRef<any>) { }
}

@Directive({ selector: 'ng-template[libGungAccordionBody]' })
export class GungAccordionBody {
  constructor(public templateRef: TemplateRef<any>) { }
}

@Directive({ selector: 'lib-gung-accordion-item' })
export class GungAccordionDirective implements AfterContentChecked {
  headerTpl: GungAccordionHeader | null = null;
  bodyTpl: GungAccordionBody | null = null;

  @Input() collapsed = true;
  @Input() id;
  @Input() customHeader = false;

  @Output()
  public show: EventEmitter<void> = new EventEmitter<void>();

  showInternal(event) {
    this.show.emit();
  }

  @ContentChildren(GungAccordionHeader, { descendants: false }) headerTpls!: QueryList<GungAccordionHeader>;
  @ContentChildren(GungAccordionBody, { descendants: false }) bodyTpls!: QueryList<GungAccordionBody>;

  ngAfterContentChecked() {
    this.headerTpl = this.headerTpls.first || null;
    this.bodyTpl = this.bodyTpls.first || null;
  }
}


@Component({
  selector: 'lib-gung-accordion',
  exportAs: 'gungAccordion',
  templateUrl: './gung-accordion.component.html',
  styleUrls: ['./gung-accordion.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class GungAccordionComponent implements AfterContentChecked {
  @Input() closeOthers = false;

  @ViewChild('accordion') accordion: NgbAccordionDirective;

  @ContentChildren(GungAccordionDirective) accordionItems!: QueryList<GungAccordionDirective>;

  ngAfterContentChecked(): void {

  }

  isExpanded(panelId: string): boolean {
    return this.accordion?.isExpanded(panelId) ?? false;
  }

  expand(panelId: string): void {
    this.accordion?.expand(panelId);
  }

  collapse(panelId: string): void {
    this.accordion?.collapse(panelId);
  }
}