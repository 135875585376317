import { Component, Input } from '@angular/core';
import { NgbActiveModal, NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { InputSaveModalComponent } from '../input-save-modal/input-save-modal.component';
import { DateUtilService } from '../../../services/date-util/date-util.service';

@Component({
  selector: 'lib-input-modal',
  templateUrl: './input-modal.component.html',
  styleUrls: ['./input-modal.component.css']
})
export class InputModalComponent extends InputSaveModalComponent {
  value: any;
  @Input()
  public btnOk = 'OK';

  @Input()
  public btnCancel = 'CANCEL';

  @Input()
  public type: 'text' | 'textarea' | 'date' = 'text'

  @Input()
  public labelType: 'text' | 'html' = 'text';

  @Input()
  public labelClass?: string;

  @Input()
  extraOptions?: any;

  public placement = ['bottom-start', 'bottom-end', 'top-start', 'top-end'];

  public firstDayOfWeek: number = this.dateUtilService.getFirstDayOfWeekRealNumber(true);

  constructor(
    public activeModal: NgbActiveModal,
    public dateUtilService: DateUtilService
  ) {
    super();
  }

  save() {
    this.delegate.modalRef.get(this.componentId).close(this.value);
  }

  dateSelected(event: NgbDate) {
    const date = this.dateUtilService.createDateFromNgbDate(event);
    this.value = date;
  }
}
