@for (item of dataTable; track index; let index = $index) {
  <tr>
    <td [attr.data-label]="'NAME' | translate">
      <div [innerHTML]="item[0]"></div>
    </td>
    <td [attr.data-label]="'PHONE' | translate">
      <div [innerHTML]="item[1]"></div>
    </td>
    <td [attr.data-label]="'EMAIL' | translate">
      {{ item[2] }}
    </td>
    <td class="action">
      <button class="btn btn-primary btn-sm" (click)="editContact(item[3])"><i class="fa-solid fa-pen-to-square"></i></button>
    </td>
  </tr>
}
