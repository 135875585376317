import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Subscription, interval, timer, Subject, of, forkJoin } from 'rxjs';
import { switchMap, map, tap, catchError, first } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LoginModalComponent } from '../components/login-modal/login-modal.component';
import { AuthService } from './auth/auth.service';
import { User } from '../models';

interface LoginCheckResponse {
  loggedIn: boolean;
  timeout: number;
}

let loginCheckService: LoginCheckService;
export const loginCheckFactory = (lcs: LoginCheckService) => {
  if (lcs) {
    loginCheckService = lcs;
  }
  return () => {
    // loginCheckService.initPoll();
    return new Promise(res => res);
  };
};

@Injectable({
  providedIn: 'root'
})
export class LoginCheckService {
  protected timerSubscription: Subscription;
  protected timerSubscription2;
  protected pollSubject = new Subject<void>();

  prevAuthenticated = false; // Previous autenticated
  prevIsAnonymous = false; // Previous Anonymous

  constructor(protected httpClient: HttpClient, protected modalService: NgbModal, protected authService: AuthService) {
    this.setupPool();
  }

  protected setupPool() {
    this.pollSubject
      .pipe(
        tap(_ => {
          if (this.timerSubscription) {
            this.timerSubscription.unsubscribe();
            this.timerSubscription = undefined;
          }
        }),
        switchMap(_ => {
          return forkJoin([
            this.authService.getIsAuthenticated().pipe(first()),
            this.authService.getHasRoles('ANONYMOUS').pipe(first())
          ]);
        }),
        switchMap(([prevAuthenticated, prevIsAnonymous]) => {
          this.prevAuthenticated = prevAuthenticated;
          this.prevIsAnonymous = prevIsAnonymous;
          return forkJoin([
            this.httpClient.get<LoginCheckResponse>('user/login-check', { headers: { maxAge: '-1' } }).pipe(first()),
            !this.prevIsAnonymous
              ? this.httpClient.get<User>('user', { headers: { maxAge: '-1' } }).pipe(
                first(),
                map(user => user?.details?.username === '_GUNG_ANONYMOUS')
              )
              : of(true)
          ]);
        }),
        catchError(_ => forkJoin([of({ loggedIn: false, timeout: null }), of(false)]))
      )
      .subscribe(([resp, isAnonymous]) => {
        if (this.prevAuthenticated && resp.loggedIn) {
          // We are logged in, we don't need to change the polling.
          // In the case with SSO login without this check we would infinitely spam the login-check, even though
          // the result was that we are logged in.
          return;
        }

        if (!this.prevAuthenticated && !resp.loggedIn) {
          // Showing the modal or in the login page
          return;
        }
        if (this.prevAuthenticated && !resp.loggedIn) {
          this.showLoginModal();
          return;
        }
        if (this.prevAuthenticated && !this.prevIsAnonymous && isAnonymous) {
          // if previous logged in and now is logged out
          this.showLoginModal();
          return;
        }
        const timeOut = resp.timeout * 1000;
        this.timerSubscription = timer(timeOut).subscribe(_ => this.pollSubject.next());
      });
  }

  public initPoll() {
    if (this.timerSubscription) {
      this.timerSubscription.unsubscribe();
      this.timerSubscription = undefined;
    }
    this.timerSubscription = timer(5000).subscribe(_ => this.pollSubject.next());
  }

  protected showLoginModal() {
    this.modalService
      .open(LoginModalComponent, { size: 'lg', backdrop: 'static', keyboard: false })
      .closed.pipe(first())
      .subscribe(_ => this.initPoll());
  }

  public forceNextPool() {
    this.pollSubject.next();
  }
}
