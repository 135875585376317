import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'lib-basic-modal',
  templateUrl: './basic-modal.component.html',
  styleUrls: ['./basic-modal.component.css']
})
export class BasicModalComponent {
  title: string;
  htmlContent: string;
  hideFooter: boolean;
  @Input()
  btnText = 'OK';

  constructor(public activeModal: NgbActiveModal) { }
}
