import { Component, OnInit } from '@angular/core';
import { HubspotContacts } from '../../models/hubspot-contacts';
import { GungTableCustomBodyComponent } from 'gung-common';
import { GungModalService } from '../../services/gung-modal/gung-modal.service';


@Component({
  selector: 'lib-hubspot-contacts-table-body',
  templateUrl: './hubspot-contacts-table-body.component.html',
  styleUrl: './hubspot-contacts-table-body.component.scss'
})
export class HubspotContactsTableBodyComponent extends GungTableCustomBodyComponent<HubspotContacts> implements OnInit{


  originalData = {};

  constructor(
    protected modalService: GungModalService
  ){
    super()
  }
  

  ngOnInit(): void {
    this.data.map(c =>{
      this.originalData[c.id]=c;
    })
  }

  editContact(id){
    this.modalService.openCreateHubspotContact(this.originalData[id].extra._customerId, this.originalData[id]).then(res =>{

    })
  }
}
