import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'lib-html-modal-editor',
  templateUrl: './html-modal-editor.component.html',
  styleUrl: './html-modal-editor.component.scss'
})
export class HtmlModalEditorComponent implements OnInit{

  @Input() 
  description?: string;

  @Input()
  title: string;

  @Input()
  public btnOk = 'OK';

  @Input()
  public btnCancel = 'CANCEL';
  
  delegate;
  componentId: string;

  constructor(public activeModal: NgbActiveModal) {
  }

  

  ngOnInit() {
    this.componentId = HtmlModalEditorComponent.name;
  }

  save() {
    this.delegate.modalRef.get(this.componentId).close(this.description);
  }
}
