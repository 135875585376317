import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Invoice } from '../../models/invoice';
import { switchMap } from 'rxjs';
import { InvoiceRow } from '../../models/invoiceRow';

@Injectable({
  providedIn: 'root'
})
export class InvoiceService {
  constructor(protected http: HttpClient) {}

  // We always want to fetch no cache for invoices, as otherwise we don't get updated values since last fetch. e.g. when we have paid with Adyen / Stripe - Adam
  headers = { headers: { maxAge: '-1' } };

  getInvoices(customerId: string, maxSize?: number, daysBack?: number): Observable<Invoice[]> {
    let url = `json/invoices/list/${customerId}`;

    if (daysBack) {
      if (!url.includes('?')) {
        url += '?'
      } else {
        url += '&'
      }
      url += `daysBack=${daysBack}`;
    }
    return this.http.get<Invoice[]>(url, this.headers);
  }

  getInvoice(invoiceId: string): Observable<Invoice> {
    const url = `json/invoices/${invoiceId}`;
    return this.http.get<Invoice>(url, this.headers);
  }

  getOpenInvoices(): Observable<Invoice[]> {
    const url = `json/open-invoices`;
    return this.http.get<Invoice[]>(url, this.headers).pipe(switchMap(response => of(response || [])));
  }

  getInvoiceRows(invoiceId: string): Observable<InvoiceRow[]> {
    const url = `json/invoice-rows/${invoiceId}`;
    return this.http.get<InvoiceRow[]>(url, this.headers);
  }
}
