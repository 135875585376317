<div class="modal-header">
  <h4 class="modal-title" translate>UPLOAD</h4>
  <button
    type="button"
    class="close"
    aria-label="Close"
    [disabled]="loading ? true : null"
    (click)="activeModal.dismiss('Cross click')"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  @if (extraDescription) {
    <div class="row">
      <div class="col {{extraDescription.class}}">
        {{extraDescription.translateTag | translate}}
      </div>
    </div> 
  }
  <div class="row">
    <div class="col-12">
      <lib-gung-input-file
        [accept]="accept"
        [multiple]="multiple"
        [enableDragDrop]="enableDragDrop"
        [uploading]="loading"
        (onFileSelect)="onFileSelect($event)"/>
    </div>
  </div>
</div>
<div class="modal-footer">
  <button
    type="button"
    class="btn btn-outline-dark"
    (click)="activeModal.close('Close click')"
    [disabled]="loading ? true : null"
    translate
  >
    CLOSE
  </button>
  <button
    type="button"
    class="btn btn-primary"
    (click)="onUpload()"
    [disabled]="loading ? true : null"
    translate
  >
    UPLOAD <i *ngIf="loading" class="fa fa-spinner fa-spin fa-lg"></i>
  </button>
</div>
