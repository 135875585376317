import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError, Observable, of } from 'rxjs';

export interface GungConfiguration {
  '@class': string,
  id: string,
  [s: string]: any
}

@Injectable({
  providedIn: 'root'
})
export class ConfigurationsService {
  constructor(protected http: HttpClient) { }

  public getConfigurations(nocache?: boolean): Observable<GungConfiguration[]> {
    const url = 'json/admin/configurations';
    const headers = {
      maxAge: nocache ? '-1' : '300'
    };
    return this.http.get<any[]>(url, { headers });
  }

  public getConfigurationsByIds(ids: string[], nocache?: boolean): Observable<any[]> {
    const url = 'json/admin/configurations/by-ids?ids=' + ids.join(',');
    const headers = {
      maxAge: nocache ? '-1' : '300'
    };
    return this.http.get<any[]>(url, { headers }).pipe(
      catchError(() => of([])) 
    );
  }

  public updateConfiguration(config: any): Observable<any> {
    const url = 'json/admin/configurations';
    return this.http.put<any>(url, config);
  }
}
