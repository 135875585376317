import { Injectable } from '@angular/core';
import { ConfigBaseFilter, ConfigFilterGroup, ConfigService, ListLayout, ListLayoutMultipleComponent, ListSortOption, PimTemplateProperties, SearchField, SelectionAction } from 'gung-list';
import { HubspotActivities, HubspotActivitiesService } from '../hubspot-activities/hubspot-activities.service';
import { ActivatedRouteSnapshot } from '@angular/router';
import { map, Observable } from 'rxjs';
import { HubspotActivityCardComponent } from '../../components/hubspot-activity-card/hubspot-activity-card.component';
import { gungComparatorHelper } from 'gung-common';

@Injectable({
  providedIn: 'root'
})
export class HubspotActivitiesListConfigService implements ConfigService<HubspotActivities>{

  public customerId: string;
  searchDisabled = true;

  constructor(protected hubspotActivitiesService: HubspotActivitiesService) { }

  getItems(route?: ActivatedRouteSnapshot): Observable<HubspotActivities[]> {
    return this.hubspotActivitiesService.getActivities(this.customerId).pipe(map(activities => {
      return activities.sort((a, b)=> gungComparatorHelper(a.extra?.hubspotActivity?.properties?.hs_createdate, b.extra?.hubspotActivity?.properties?.hs_createdate, -1))
    }))
  }
  getFilters(route?: ActivatedRouteSnapshot): ConfigBaseFilter<HubspotActivities>[] {
    return []
  }
  getSortOptions(route?: ActivatedRouteSnapshot): ListSortOption<HubspotActivities>[] {
    return [];
  }
  getBatchSizes(route?: ActivatedRouteSnapshot): number[] | undefined {
    return [50];
  }
  getLayouts(route?: ActivatedRouteSnapshot): ListLayout<HubspotActivities>[] {
    return [
      {
        getIconClass: () => '',
        getListItemComponent: () => HubspotActivityCardComponent,
        getListLayoutComponent: () => ListLayoutMultipleComponent,
        getName: () => 'hubspot grid'
      }
    ];
  }


  getSearchTerms(item: HubspotActivities): string[] {
    return [item.id]
  }
  getItemId(item: HubspotActivities): string {
    return item.id
  }
  



  
}
