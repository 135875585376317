import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { SelectedCustomerService, AuthService, gungValidateEmail } from 'gung-standard';
import { DeliveryLocationsJeevesComponent, DeliveryLocationsService } from 'gung-standard-jeeves';
import { first, mergeMap, forkJoin, of, map } from 'rxjs';
import { environment } from '../../../../environments/environment';
import {
  CheckoutSettingsGroup,
  HlDisplayPortalFeaturesService
} from '../../../services/hl-display-portal-features.service';

@Component({
  selector: 'app-hl-shipping-step-portal',
  templateUrl: './hl-shipping-step-portal.component.html',
  styleUrl: './hl-shipping-step-portal.component.css'
})
export class HlShippingStepPortalComponent extends DeliveryLocationsJeevesComponent implements OnInit {
  public loading = true;
  public emailTemporaryInvalidError: boolean = false;

  isSales = false;
  fieldError: { [s: string]: boolean } = {};
  orderConfirmError = false;
  checkoutSettings: CheckoutSettingsGroup;

  constructor(
    protected selectedCustomerService: SelectedCustomerService,
    protected authService: AuthService,
    protected portalFeatureService: HlDisplayPortalFeaturesService,
    deliveryLocationsService: DeliveryLocationsService,
    formBuilder: FormBuilder
  ) {
    super(selectedCustomerService, deliveryLocationsService, formBuilder, authService);
  }

  ngOnInit() {
    // super.ngOnInit();
    this.onNextBtnClicked.subscribe(_ => this.handleNextButtonClicked());
    this.selectedCustomerService
      .getSelectedCustomer()
      .pipe(
        first(),
        mergeMap(selectedCustomer =>
          forkJoin({
            selectedCustomer: of(selectedCustomer),
            deliveryLocations: this.deliveryLocationsService.getDeliveryLocationsForCustomer(selectedCustomer.id),
            checkoutSettings: this.portalFeatureService.getPortalFeatureGroup('orderCheckoutSettingsGroup').pipe(
              first(),
              map((settingsGroup: CheckoutSettingsGroup) => settingsGroup)
            )
          })
        )
      )
      .subscribe(({ selectedCustomer, deliveryLocations, checkoutSettings }) => {
        this.selectedCustomer = selectedCustomer;
        this.checkoutSettings = checkoutSettings;

        this.initForm();

        this.deliveryLocations = [...deliveryLocations];

        if (this.selectedCustomer.extra.kus.ordlevplats1) {
          const deliveryLocation = this.deliveryLocations.find(location => {
            return location.extra.lp.ordlevplats1 === this.selectedCustomer.extra.kus.ordlevplats1;
          });
          this.deliveryLocationId = deliveryLocation.id;
        } else {
          this.deliveryLocations.unshift({
            id: this.selectedCustomer.id,
            name: this.selectedCustomer.name,
            extra: this.selectedCustomer.extra,
            timestamp: new Date()
          });
          this.deliveryLocationId = this.deliveryLocations[0].id;
        }

        const dl = this.deliveryLocations.find(dl => dl.id === this.deliveryLocationId);
        if (dl) {
          (dl as any).selected = true;
        }
      });

    this.checkout.extra.confirmationEmails = this.checkout.extra.confirmationEmails
      ? this.checkout.extra.confirmationEmails
      : [''];
    if (this.checkout.extra.confirmationEmails.length === 0) {
      this.checkout.extra.confirmationEmails.push('');
    }

    this.authService.getCurrentUser().subscribe(user => {
      if (user.roles.indexOf('SALES') >= 0) {
        this.isSales = true;
        this.checkout.extra.confirmationEmails.push(user.email);
      }
    });
  }

  protected handleNextButtonClicked(): void {
    if (!!this.checkout.extra._msFlowOrderConfirmEmail && !this.checkout.extra._msFlowOrderConfirmEmail.includes('@')) {
      this.orderConfirmError = true;
      return;
    } else {
      this.orderConfirmError = false;
    }

    if (!this.isSales) {
      if (!this.checkout.extra._msFlowOrderConfirmEmail) {
        this.orderConfirmError = true;
        return;
      } else {
        this.orderConfirmError = false;
      }
    }

    if (!!this.checkout.extra._emailQuoteTemporary && this.checkout.extra._emailQuoteTemporary.includes(';')) {
      if (this.checkout.extra._emailQuoteTemporary.split(';').findIndex(m => !gungValidateEmail(m.trim())) > -1) {
        this.emailTemporaryInvalidError = true;
        return;
      } else {
        this.emailTemporaryInvalidError = false;
      }
    } else {
      this.emailTemporaryInvalidError = false;
    }

    this.fieldError['kundref2'] = false;

    this.fieldError['kundbestnr'] = false;

    if (Object.keys(this.fieldError).filter(k => this.fieldError[k]).length > 0) {
      return;
    }

    super.handleNextButtonClicked();

    /*
    if (this.formOh.get('kundbestnr').value === '') {
      this.formOh.get('kundbestnr').setErrors({ 'required': true });
      this.formOh.get('kundbestnr').markAsTouched();
    }*/

    // this.stepDone.emit(this.checkout);
  }

  public getLabel(): string {
    return 'SHIPPING';
  }

  protected initForm() {
    let kundref2BeforeSuper = this.checkout.extra.oh.kundref2 || '';

    super.initForm();

    // Make kundbestnr not required, unless it is required by the checkout settings
    // In standard, it is required
    if (!this.checkoutSettings.makeCustomerPORequired) {
      this.formOh.controls['kundbestnr'].setValidators([]);
    }

    // This will ensure that super does not set kundref2 as to the user's name, which is the default value in Jeeves
    if (this.formOh.get('kundref2').value !== kundref2BeforeSuper) {
      this.formOh.get('kundref2').setValidators([]);
      this.formOh.get('kundref2').setValue(kundref2BeforeSuper);
    }

    this.form.addControl('ordlevplats1', this.formBuilder.control(this.checkout.extra.procargs.ordlevplats1 || ''));
    this.checkout.extra.oh.q_hl_sendttlinkemail = this.selectedCustomer.extra.kus.q_hl_sendttlinkemail
      ? '1'
      : undefined;
    if (!this.checkout.extra.oh.q_hl_emailtt) {
      this.checkout.extra.oh.q_hl_emailtt = this.selectedCustomer.extra.kus.q_hl_sendttlinkemail
        ? this.checkout.extra.kus.q_hl_emailtt
        : undefined;
    }
  }

  emailttChange(table, value) {
    if (!value || value === '') {
      this.checkout.extra[table].q_hl_emailtt = undefined;
      this.checkout.extra[table].q_hl_sendttlinkemail = undefined;
      return;
    }

    this.checkout.extra[table].q_hl_sendttlinkemail = '1';
  }

  protected afterDeliveryLocationSet(deliveryLocationId: string): void {
    super.afterDeliveryLocationSet(deliveryLocationId);

    this.checkout.extra._selectedDeliveryLocationId = deliveryLocationId;
    this.checkout.extra._selectedDeliveryLocation = this.selectedDeliveryLocation.extra.fr;

    if (this.selectedDeliveryLocation.extra.fr.ftgnr) {
      this.checkout.extra.procargs.ordlevplats1 = this.selectedDeliveryLocation.extra.fr.ftgnr;
    }
  }
}
