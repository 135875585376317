import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { EdiOrderItem } from '../../../../models/edi';
import { EdiService } from '../../../../services/edi/edi.service';
import { gungAddRemoveSpinner } from '../../../../utils/gung-utils';

@Component({
  selector: 'lib-edi-modal-skip-validation',
  templateUrl: './edi-modal-skip-validation.component.html',
  styleUrl: './edi-modal-skip-validation.component.scss'
})
export class EdiModalSkipValidationComponent implements OnInit {
  @Input()
  ediOrderId: string;

  @Input()
  ediOrderItem: EdiOrderItem;

  @Input()
  index: number;

  validate: boolean;

  constructor(
    public activeModal: NgbActiveModal,
    protected ediService: EdiService
  ) {}

  ngOnInit(): void {
    console.log('EdiModalChangeQuantityComponent [init]', this.ediOrderItem);
  }

  save(element) {
    console.log('EdiModalChangeQuantityComponent [save]', this.ediOrderItem);
    gungAddRemoveSpinner(element.target);

    this.ediService.postSkipValidation(this.ediOrderId, this.index, this.ediOrderItem).subscribe(
      (response: any) => {
        this.activeModal.close({ response, validate: this.validate });
      },
      error => {
        // this.activeModal.close({error});
        gungAddRemoveSpinner(element.target);
      },
      () => {
        gungAddRemoveSpinner(element.target);
      }
    );
  }
}
