import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { HubspotContactService } from '../../services/hubspot-contact/hubspot-contact.service';
import { first } from 'rxjs';
import { gungAddRemoveSpinner } from 'gung-common';
import { HubspotContacts } from '../../models/hubspot-contacts';

@Component({
  selector: 'lib-hubspot-create-contact-modal',
  templateUrl: './hubspot-create-contact-modal.component.html',
  styleUrl: './hubspot-create-contact-modal.component.scss'
})
export class HubspotCreateContactModalComponent implements OnInit{
 
  customerId: string;
  contactForm!: FormGroup;

  delegate;
  componentId: string;

  editContact: HubspotContacts;

  constructor( 
    protected fb: FormBuilder,
    public activeModal: NgbActiveModal,
    protected hubspotContactService: HubspotContactService
  ) {}

  ngOnInit(): void {
    this.componentId = HubspotCreateContactModalComponent.name;
    this.initForm();
  }

  initForm(): void{
    let contact; 
    if(this.editContact){
      contact = {
        email: this.editContact.extra.hubspotContact.properties.email,
        firstname: this.editContact.extra.hubspotContact.properties.firstname,
        lastname: this.editContact.extra.hubspotContact.properties.lastname,
        phone: this.editContact.extra.hubspotContact.properties.phone, 
      }
      
    }
    this.contactForm = this.fb.group({
      email: [contact?.email || '', [Validators.required, Validators.email]],
      firstname: [contact?.firstname || '', Validators.required],
      lastname: [contact?.lastname || '', Validators.required],
      phone: [contact?.phone || ''],
    });
  }

  save({target}): void{
    if (this.contactForm.valid) {
      gungAddRemoveSpinner(target);
      this.contactForm.value;
      
      if(!this.editContact){
        const payload = {
          extra: {
            hubspotContact: {
              properties: this.contactForm.value,
            },
          },
        };
        this.hubspotContactService.createHubspotContact(this.customerId, payload).pipe(first()).subscribe(contact =>{
          gungAddRemoveSpinner(target);
          this.delegate.modalRef.get(this.componentId).close({});
        });
      }else{
        const payload = {
          ...this.editContact,
          extra: {
            ...this.editContact.extra,
            hubspotContact: {
              ...this.editContact.extra.hubspotContact,
              properties: {
                ...this.editContact.extra.hubspotContact.properties,
                ...this.contactForm.value
              },
            },
          },
        };
        this.hubspotContactService.editHubspotContact(this.customerId, payload).pipe(first()).subscribe(contact =>{
          gungAddRemoveSpinner(target);
          this.delegate.modalRef.get(this.componentId).close({});
        });
      }
    } else {
      this.contactForm.markAllAsTouched();
      return;
    }
    
  }
}
