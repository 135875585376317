import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { HubspotContactService } from '../../services/hubspot-contact/hubspot-contact.service';
import { GungTableConfig } from 'gung-common';
import { HubspotContacts } from '../../models/hubspot-contacts';
import { first, Subject, takeUntil } from 'rxjs';
import { GungModalService } from '../../services/gung-modal/gung-modal.service';
import { HubspotContactsTableBodyComponent } from '../hubspot-contacts-table-body/hubspot-contacts-table-body.component';

@Component({
  selector: 'lib-hubspot-contact-table',
  templateUrl: './hubspot-contact-table.component.html',
  styleUrl: './hubspot-contact-table.component.scss'
})
export class HubspotContactTableComponent implements OnInit, OnDestroy {
  @Input()
  customerId: string;

  contactTable: GungTableConfig<HubspotContacts>;

  unsubscribe: Subject<void> = new Subject();

  constructor(
    protected hubspotContactService: HubspotContactService,
    protected modalService: GungModalService
  ) {}
  
  ngOnInit(): void {
    this.hubspotContactService
      .getHubspotContactsObservable(this.customerId)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(contacts => {
        const data = contacts.map(c => {
          c.extra._name =
            c.name + '<br/>' + (c.extra.hubspotContact.properties.jobtitle
              ? c.extra.hubspotContact.properties.jobtitle + ' @ '
              : '') + c.extra.hubspotContact.properties.company;
          c.extra._phone =
            (c.extra.hubspotContact.properties.mobilephone
              ? c.extra.hubspotContact.properties.mobilephone + '<br/>'
              : '') + (c.extra.hubspotContact.properties.phone ? c.extra.hubspotContact.properties.phone : '');
          c.extra._customerId = this.customerId;
          return c;
        });

        this.contactTable = {
          headers: [
            {
              name: 'NAME',
              path: 'extra._name',
              sortable: true,
              isHtmlField: true
            },
            {
              name: 'PHONE',
              path: 'extra._phone',
              sortable: false,
              isHtmlField: true
            },
            {
              name: 'EMAIL',
              path: 'extra.hubspotContact.properties.email',
              sortable: true
            },
            {
              name: '',
              path: 'id',
              sortable: false
            }
          ],
          data: data,
          enableSearch: false,
          useNumberPaginantion: false,
          customBodyComponent: HubspotContactsTableBodyComponent
        };
      });
  }

  create(): void{
    this.modalService.openCreateHubspotContact(this.customerId).then(res=>{
    })

  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

}
