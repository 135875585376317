<div *ngIf="!!data">
  <div *ngFor="let hubspotActivity of data" class="activity-card">
    <div class="activity-header" (click)="toogleActivity(hubspotActivity.id)">
      <div class="row">

        <div class="col">
          <div class="activity-title">
            @if (!expandActivity[hubspotActivity.id]) {
              <i class="fa-regular fa-chevron-right mr-3 text-primary"></i>
            } @else {
              <i class="fa-regular fa-chevron-down mr-3 text-primary"></i>
            }
            <span translate>NOTE</span>
        </div>
        </div>
        <div class="col ml-auto text-right">
          {{ hubspotActivity.extra?.hubspotActivity?.properties?.hs_createdate | date: dateUtilService.dateFormat }}
        </div>
      </div>
    </div>
    <div class="activity-body" [class.expanded]="expandActivity[hubspotActivity.id]">
      <div [innerHTML]="hubspotActivity.extra?.hubspotActivity?.properties?.hs_note_body"></div>
    </div>
  </div>
</div>
