<div class="checkout-car-list">
  <div class="loading" *ngIf="loading">
    <div class="sk-wandering-cubes">
      <div class="sk-cube bg-primary sk-cube-1"></div>
      <div class="sk-cube bg-primary sk-cube-2"></div>
    </div>
  </div>
  <div *ngIf="!isMultiDimension" cdkDropList (cdkDropListDropped)="drop($event)">
  
      @if(mappedData && showDates){
        
        <div class="d-flex delivery-global-date justify-content-end mb-3 align-items-center">
          <span translate class="mr-2">EST_DISPATCH_DATE</span>
          <lib-delivery-date-picker
            class="request-delivery-date"
            label=""
            [useSameDayInicialDate]="true"
            [minInitialDate]="minPossibleDate"
            [currentselectedDate]="deliveryDate"
            (selectedDate)="dateSelected($event)"
          ></lib-delivery-date-picker>
        </div>
      }
      
    <div class="row">
      <div class="col-1 col-lg-2"></div>
      <div class="col-11 col-lg-10">
        <div class="row">
          <div class="col"></div>
          <div class="col-4"></div>
          <div class="col-2 text-center d-none d-md-block">
            <span translate>AVAILABILITY</span>
          </div>
          <div class="col-2 text-center d-none d-md-block">
            @if (showDates) {
              <span class="d-block mb-3" translate>EST_DISPATCH_DATE</span>
            }
          </div>
          <div class="col-2 text-center d-none d-md-block">
            <span translate>PRICE</span>
          </div>
          <div class="col-1 text-right d-none d-md-block">
            <span translate>TOTAL</span>
          </div>
        </div>
      </div>

      <div class="col-12">
        <div class="border-bottom"></div>
      </div>
    </div>
    @for (
      mappedDataRow of !sortByOptions ? mappedData : (mappedData | orderBy: 'asc' : sortByFn);
      track mappedDataRow.product.id;
      let i = $index
    ) {
      <div class="row" cdkDrag>
        <div class="col example-custom-placeholder" *cdkDragPlaceholder></div>
        <div class="col-1 col-lg-2 d-flex actions-row mt-1">
          <button type="button" class="btn btn-link" style="cursor: move" cdkDragHandle>
            <i class="fa-solid fa-arrow-right-arrow-left" style="transform: rotate(90deg)"></i>
          </button>
          <button type="button" class="btn btn-light rounded-circle btn-m-l" (click)="removeRow(mappedDataRow.cartRow)">
            <i class="fa-solid fa-xmark"></i>
          </button>
          <button type="button" class="btn btn-light rounded-circle btn-m-l" (click)="openCommentsModal(mappedDataRow)">
            <i class="far fa-file-alt"></i>
          </button>
        </div>
        <div class="col-11 col-lg-10">
          <div class="row">
            <div class="col-3 col-md-1">
              <div
                class="navigate-to-product-details cart-col product-image"
                [routerLink]="['/product/', mappedDataRow.product.id]"
              >
                <img
                  class="img-fluid my-3"
                  style="max-width: 64px"
                  *ngIf="mappedDataRow.product.extra.images && mappedDataRow.product.extra.images.length > 0"
                  [src]="
                    mappedDataRow.product.extra.images[0].s3Uri
                      | gungImageUrl: 'type:thumbnail' : 'etag:' + mappedDataRow.product.extra.images[0].s3ETag
                  "
                  alt=""
                />
              </div>
            </div>
            <div class="col-md-4 col-8 d-flex align-items-center pmobile-b pb-2">
              <div>
                <div
                  class="navigate-to-product-details cart-col"
                  [routerLink]="['/product/', mappedDataRow.product.id]"
                >
                  <div class="mb-3">
                    <b>{{ mappedDataRow.product.id }}</b> - {{ mappedDataRow.product.name }}
                    @if (isSales) {
                      <div class="product-text">{{ getDescription(mappedDataRow) }}</div>
                    }
                  </div>
                </div>
                @if (addInstallationCharge && installationChargeProductId === mappedDataRow.product.id && !isSales) {
                  <span>{{ 'QUANTITY' | translate }}: </span>{{ mappedDataRow.cartRow.qty }}
                } @else {
                  <app-hl-display-buy-btn
                    [product]="mappedDataRow.product"
                    [id]="mappedDataRow.product.id"
                    [productPartialId]="mappedDataRow.cartRow.productPartialId"
                    class="d-block"
                    style="max-width: 125px"
                  ></app-hl-display-buy-btn>
                }
              </div>
            </div>
            <div class="col-12 col-md-2 d-flex align-items-center pmobile-b justify-content-md-center pb-2">
              <div class="col-auto d-block d-sm-block d-md-none"><span translate>AVAILABILITY</span>:&nbsp;</div>
              <app-hl-display-availability
                [availability]="stockId && mappedDataRow.product.extra.availabilities[stockId]"
              />
            </div>
            <div class="col-12 col-md-2 d-flex align-items-md-center pmobile-b justify-content-md-center pb-2">
              <div class="col-auto d-block d-sm-block d-md-none"><span translate>EST_DISPATCH_DATE</span>:&nbsp;</div>
              @if (showDates) {
                <!-- @if (isSales) {
                  <app-hlsales-cart-delivery-date-picker
                    [checkout]="checkout"
                    [deliveryMethod]="checkout.extra.procargs.levsattkod"
                  ></app-hlsales-cart-delivery-date-picker>
                } @else if (isProjectManager) {
                  <lib-delivery-date-picker
                    class="request-delivery-date"
                    label=""
                    [minInitialDate]="mappedDataRow.minDate"
                    [initialDate]="
                      mappedDataRow.deliveryDate ? mappedDataRow.deliveryDate : formatNgbDate(mappedDataRow.minDate)
                    "
                    (selectedDate)="updateDeliveryDate(mappedDataRow, $event)"
                    [currentselectedDate]="mappedDataRow.deliveryDate"
                    [disabled]="!isSales && !isProjectManager"
                    [readonly]="!isSales && !isProjectManager"
                  />
                } @else {
                  <app-hl-delivery-date-picker
                    class="p-4"
                    [deliveryMethod]="checkout.extra.procargs.levsattkod"
                    [productId]="mappedDataRow.product.id"
                    [partialId]="mappedDataRow.cartRow.productPartialId"
                    (selectedDate)="setSelectedDeliveryDate($event)"
                  ></app-hl-delivery-date-picker>
                } -->
                {{ mappedDataRow.deliveryDate }}
              }
            </div>
            <div class="col-12 col-md-2 d-flex align-items-center pmobile-b justify-content-md-center pb-2">
              <div class="col-auto d-block d-sm-block d-md-none"><span translate>PRICE</span>:&nbsp;</div>
              <div *ngIf="isSales; else showOnlyPrice">
                <input
                  type="number"
                  class="form-control"
                  [class.small-number-input]="!currentFlow.requireAvailability && !currentFlow.useAvailabilities"
                  [ngModel]="mappedDataRow.price.cartRowUnitPrice.value"
                  (change)="setPrice($event, mappedDataRow.cartRow)"
                  (click)="$event.preventDefault(); $event.stopPropagation()"
                />
              </div>
              <ng-template #showOnlyPrice>
                <lib-price [price]="mappedDataRow.price" type="cartRowUnitPrice"></lib-price>
                <span *ngIf="mappedDataRow.price.cartRowDiscountPercent !== 0">
                  (-{{ mappedDataRow.price.cartRowDiscountPercent }}%)
                </span>
              </ng-template>
            </div>
            <div class="col-12 col-md-1 d-flex align-items-center pmobile-b justify-content-md-end pb-2">
              <div class="col-auto d-block d-sm-block d-md-none"><span translate>TOTAL</span>:&nbsp;</div>
              <lib-price [price]="mappedDataRow.price" type="cartRowTotalPriceInclRowDiscount"></lib-price>
            </div>
          </div>
        </div>
        <div class="col-12">
          <div class="border-bottom"></div>
        </div>
      </div>
    } @empty {
      <div class="w-100 text-center">There are no items.</div>
    }
  </div>
  <div class="pt-3" *ngIf="isSales || isProjectManager">
    <button
      *ngIf="isSales || isProjectManager"
      class="btn btn-primary btn-circle"
      type="button"
      (click)="addNewOrderLine()"
    >
      <i class="fas fa-plus"></i>
    </button>

    <button
      *ngIf="shouldAddInstallationChargeManually"
      class="btn btn-primary btn-circle mx-2"
      type="button"
      (click)="addInstallationChargeFixed(0.1)"
    >
      10%
    </button>
    <button
      *ngIf="shouldAddInstallationChargeManually"
      class="btn btn-primary btn-circle"
      type="button"
      (click)="addInstallationChargeFixed(0.08)"
    >
      8%
    </button>
  </div>
</div>
