import { CartRow } from "../types";

export const replaceCartRowReducer = (
  current: CartRow[],
  currentCartRow: CartRow,
  newCartRow: CartRow
): CartRow[] => {
  let result = current || [];
  const { productId, productPartialId, targetStockId } = currentCartRow;
  const foundIndex: number = result.findIndex(cr => cr.productId === productId && cr.productPartialId === productPartialId && cr.targetStockId === targetStockId);
  if (foundIndex > -1) {
    result.splice(foundIndex, 1, newCartRow);
  }
  return result;
};
