import { ProductPartialIdPayload } from '../actions';
import { CartRow, CartRowIdentification } from '../types';
import { filterOutRow, getRowFromRows } from './common';

const createRow = (productId: string, date: Date, targetStockId?: string, productPartialId?: string): CartRow => ({
  productId,
  productPartialId,
  targetStockId,
  extra: {},
  qty: 0,
  timeAdded: date,
  timeUpdated: date
});

export const setProductPartialIdReducer = (
  rows: CartRow[],
  identification: CartRowIdentification,
  productPartialId: string,
  oldProductPartialId: string
): CartRow[] => {
  const currentDate = new Date();
  const { productId, targetStockId } = identification;
  if (!productPartialId) {
    productPartialId = productId + '_' + currentDate.getTime();
  }
  const oldRow = getRowFromRows(rows, productId, targetStockId, oldProductPartialId) || createRow(productId, currentDate, targetStockId);
  const newRow = {
    ...oldRow,
    productPartialId,
    timeUpdated: currentDate
  };
  return [...rows.filter(r => filterOutRow(r, productId, targetStockId, oldProductPartialId)), newRow];
};

export const bulksetProductPartialIdReducer = (rows: CartRow[], payload: ProductPartialIdPayload[]): CartRow[] => {
  for (const item of payload) {
    rows = setProductPartialIdReducer(
      rows,
      { productId: item.productId, productPartialId: item.productPartialId, targetStockId: item.targetStockId },
      item.productPartialId,
      item.oldProductPartialId
    );
  }

  return rows;
};
