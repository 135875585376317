import { Component, Input, OnInit } from '@angular/core';
import { HubspotActivitiesService } from '../../services/hubspot-activities/hubspot-activities.service';
import { HubspotActivitiesListConfigService } from '../../services/hubspot-activities-list-config/hubspot-activities-list-config.service';
import { TranslateService } from '@ngx-translate/core';
import { GungModalService } from '../../services/gung-modal/gung-modal.service';

@Component({
  selector: 'lib-hubspot-customer-activities-table',
  templateUrl: './hubspot-customer-activities-table.component.html',
  styleUrl: './hubspot-customer-activities-table.component.scss'
})
export class HubspotCustomerActivitiesTableComponent implements OnInit{

  @Input() customerId: string;
  
  constructor(
    protected hubspotActivitiesConfigService: HubspotActivitiesListConfigService,
    protected gungModalService: GungModalService,
    protected hubspotActivitiesService: HubspotActivitiesService,
    protected translateService: TranslateService
  ){
    
  }
  ngOnInit(): void {
    this.hubspotActivitiesConfigService.customerId = this.customerId;
  }

  create(){
    this.gungModalService.openHtmlModalEditorComponent(
      this.translateService.instant('ADD_NOTE'),
    ).then(description =>{
      this.hubspotActivitiesService.createActivity(this.customerId, description).subscribe(activities =>{
        console.log('activities',activities);
        this.gungModalService.openConfirmYesNoModal(
          this.translateService.instant('SAVED'),
          this.translateService.instant('ACTIVITY_SAVED_TAKE_TIME'),
          {},
          this.translateService.instant('OK'),
          null
        )
      })
    })
  }

}
