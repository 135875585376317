import { AfterViewInit, Component } from '@angular/core';
import { ListItemRendererComponent } from 'gung-list';
import { HubspotActivities } from '../../services/hubspot-activities/hubspot-activities.service';
import { DateUtilService } from 'gung-common';

@Component({
  selector: 'lib-hubspot-activity-card',
  templateUrl: './hubspot-activity-card.component.html',
  styleUrl: './hubspot-activity-card.component.scss'
})
export class HubspotActivityCardComponent extends ListItemRendererComponent<HubspotActivities> implements AfterViewInit {

  expandActivity: { [key: string]: boolean } = {};

  constructor(public dateUtilService: DateUtilService){
    super();
  }

  ngAfterViewInit(): void {
    this.renderFinished?.emit();
  }

  toogleActivity(id: string){
    if(!this.expandActivity[id]){
      this.expandActivity[id] = true;
    }else{
      this.expandActivity[id] = false;
    }
  }
}