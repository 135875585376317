import { Component, ElementRef, EventEmitter, Inject, Input, OnInit, Optional, Output, ViewChild } from '@angular/core';
import {
  AdyenOrderStatus,
  AdyenOrderType,
  AdyenPaymentLinkResponse,
  AdyenService
} from '../../services/adyen/adyen.service';
import { catchError, filter, first, firstValueFrom, forkJoin, of, repeat, switchMap, takeWhile } from 'rxjs';
import { CheckoutObject } from '../../models/checkout';

import {
  AdditionalDetailsActions,
  AdditionalDetailsData,
  AdyenCheckout,
  ApplePay,
  Card,
  CoreConfiguration,
  Dropin,
  GooglePay,
  Klarna,
  PaymentCompletedData,
  PayPal,
  SubmitActions,
  SubmitData,
  Swish,
  UIElement
} from '@adyen/adyen-web';
import { GungAnalyticsConsentService } from '../../services/gung-analytics-consent.service';

interface AdyenPaymentLinkRequest {
  currency: string;
  countryCode: string;
  description: string;
  shopperLocale: string;
  shopperReference: string;
  order: CheckoutObject;
}

@Component({
  selector: 'lib-adyen-payment',
  templateUrl: './adyen-payment.component.html',
  styleUrls: ['./adyen-payment.component.css']
})
export class AdyenPaymentComponent implements OnInit {
  @Input() checkout: any;
  @Input() payByLink: boolean;
  @Input() isAdvancedFlow: boolean;
  @Input() allowCancellationOfLink: boolean;
  @Input() adyenOrderType: AdyenOrderType;

  @Output() onPaymentCompleted = new EventEmitter<AdyenPaymentCompletedEvent>();
  @Output() onPaymentFailed = new EventEmitter<AdyenPaymentFailedEvent>();
  @Output() beforeSubmit = new EventEmitter<any>();

  errorMessage: string;
  loading = false;
  protected paymentLink: AdyenPaymentLinkResponse;

  /* Required for mounting the Adyen components*/
  @ViewChild('hook', { static: true })
  hook: ElementRef;
  dropIn: Dropin | undefined;

  private pspReference: string;
  private merchantReference: string;
  private paymentMethodType: string;

  public shouldContinuePolling: boolean = true;
  public isPolling: boolean = false;
  public currentStatus: string = '';
  public currentStatusPercentage: number = 0;

  constructor(
    @Optional()
    @Inject('environment')
    protected environment: { [s: string]: any },
    protected adyenService: AdyenService,
    protected gungAnalyticsConsentService: GungAnalyticsConsentService
  ) {}

  ngOnInit(): void {
    this.loading = true;
    this.adyenService
      .decorateCheckout(this.checkout)
      .pipe(first())
      .subscribe(checkout => {
        this.checkout = checkout;
        if (this.payByLink) {
          this.createPaymentLink();
        } else if (this.isAdvancedFlow) {
          this.initiateAdvancedFlow();
        } else {
          console.log(this.isAdvancedFlow);
          this.createPaymentSession();
        }
      });
  }

  private createPaymentSession() {
    this.adyenService
      .createSession(this.checkout)
      .pipe(first())
      .subscribe({
        next: this.handleSessionCreated,
        error: e => {
          console.error(e);
          this.errorMessage = 'COULD_NOT_CREATE_ADYEN_SESSION';
          this.loading = false;
        }
      });
  }

  private createPaymentLink() {
    // TODO - add Voyado ID here for cellbes
    this.adyenService
      .createPaymentLink({ order: this.checkout } as AdyenPaymentLinkRequest)
      .pipe(first())
      .subscribe({
        next: this.handlePaymentLinkCreated,
        error: e => {
          console.error(e);
          this.errorMessage = 'COULD_NOT_CREATE_ADYEN_PAYMENT_LINK';
          this.loading = false;
        }
      });
  }

  private initiateAdvancedFlow(): void {
    forkJoin([
      this.adyenService.createAdyenOrder(this.checkout, this.adyenOrderType).pipe(first()),
      this.adyenService.getPaymentMethodCountryCode(this.checkout).pipe(first())
    ])
      .pipe(
        first(),
        switchMap(([adyenOrder, countryCode]) =>
          forkJoin([
            this.adyenService
              .fetchPaymentMethods({
                merchantReference: adyenOrder.merchantReference,
                amount: adyenOrder.amount,
                shopperLocale: navigator.language,
                countryCode
              })
              .pipe(first()),
            of(adyenOrder),
            of(countryCode)
          ])
        ),
        first()
      )
      .subscribe(([paymentMethods, adyenOrder, countryCode]) => {
        let adyenConfig: CoreConfiguration = {
          paymentMethodsResponse: paymentMethods,
          amount: adyenOrder.amount,
          environment: this.environment.adyenEnvironment,
          clientKey: this.environment.adyenClientKey,
          locale: navigator.language,
          countryCode,
          //TODO: Add country and locale
          // analytics: { enabled: this.gungAnalyticsConsentService.isUserConsentingToAnalytics() },
          onSubmit: async (state: SubmitData, dropin: UIElement, actions: SubmitActions) => {
            try {
              if (!state.isValid) {
                actions.reject();
                return;
              }

              const result = await firstValueFrom(
                this.adyenService.createPayment(adyenOrder.merchantReference, this.adyenOrderType, state.data)
              );
              if (!result.resultCode) {
                actions.reject();
                return;
              }

              this.merchantReference = adyenOrder.merchantReference;

              if (result.resultCode === 'Authorised') {
                this.pspReference = result.pspReference;
                this.paymentMethodType = result.paymentMethod.type;
              } else if ((result.resultCode = 'Pending')) {
                // For e.g. Swish
                this.paymentMethodType = result.action?.paymentMethodType;
              }

              actions.resolve(result);
            } catch (e) {
              // Reject on errors, for Adyen to display that an error has occured.
              actions.reject();
              return;
            }
          },
          onAdditionalDetails: async (
            state: AdditionalDetailsData,
            dropin: UIElement,
            actions: AdditionalDetailsActions
          ) => {
            try {
              const result = await firstValueFrom(this.adyenService.getPaymentDetails(state));
              if (!result.resultCode) {
                actions.reject();
                return;
              }

              if (result.resultCode === 'Authorised' || result.resultCode === 'Received') {
                this.merchantReference = this.merchantReference || result.merchantReference;
                this.pspReference = result.pspReference;
                // Does not seem to allways exist on the result object (e.g. for Swish)
                this.paymentMethodType = result.paymentMethod?.type || this.paymentMethodType;
              }

              actions.resolve(result);
            } catch (e) {
              // Reject on errors, for Adyen to display that an error has occured.
              actions.reject();
              return;
            }
          },
          onPaymentCompleted: (data: PaymentCompletedData, element?: UIElement) => {
            console.log('PAYMENTCOMPLETED ADYENCOMPONENT');
            this.onPaymentCompleted.emit({
              data: data,
              pspReference: this.pspReference,
              merchantReference: this.merchantReference,
              selectedPaymentMethod: this.paymentMethodType,
              element: element
            });
          },
          onPaymentFailed: (data?: PaymentCompletedData, element?: UIElement) => {
            this.onPaymentFailed.emit({
              data: data,
              merchantReference: this.merchantReference
            });
          }
        };
        AdyenCheckout(adyenConfig).then(checkout => {
          this.loading = false;
          console.log('checkout', checkout);
          this.dropIn = new Dropin(checkout, {
            paymentMethodsConfiguration: {
              card: {
                hasHolderName: true,
                holderNameRequired: true
              }
            },
            paymentMethodComponents: [Card, Klarna, ApplePay, PayPal, GooglePay, Swish]
          }).mount(this.hook.nativeElement);
        });
      });
  }

  private handleSessionCreated = session => {
    this.loading = false;
    let adyenConfig: CoreConfiguration = {
      environment: this.environment.adyenEnvironment,
      clientKey: this.environment.adyenClientKey,
      session: session,
      onPaymentCompleted: (data, element?) =>
        this.onPaymentCompleted.emit({
          data: data,
          pspReference: undefined,
          merchantReference: session.id,
          selectedPaymentMethod: undefined,
          element: element
        }),
      beforeSubmit: (state, element, actions) => {
        console.log('Before submit', state);
        this.beforeSubmit.emit(state);
        return actions.resolve(state);
      }
    };

    AdyenCheckout(adyenConfig).then(checkout => {
      const dropIn = new Dropin(checkout).mount('#adyen-container');
    });
  };

  private handlePaymentLinkCreated = (paymentLink: AdyenPaymentLinkResponse) => {
    this.loading = false;
    this.paymentLink = paymentLink;
    this.pollForPaymentLinkUpdates();
  };

  private pollForPaymentLinkUpdates() {
    // Check link status on `json/adyen/link/${this.paymentLink.id}`. When status is 'COMPLETED' we can start polling for order
    // Get finalized order from `json/adyen-order/${this.paymentLink.reference}`
    // Mock AdyenPaymentCompletedEvent and emit it when we have an order id. event.data.resultCode needs to be 'Authorised'
    // this.onPaymentCompleted.emit({ data: '', orderId: '', element: '' })

    this.isPolling = true;
    this.currentStatus = 'POLLING_PAYMENT_STATUS';
    this.currentStatusPercentage = 33;
    this.shouldContinuePolling = true;

    this.adyenService
      .pollLinkStatus(this.paymentLink.id)
      .pipe(
        catchError(() => of(undefined)),
        repeat({ delay: 3000 }),
        takeWhile(res => this.shouldContinuePolling && res?.status !== 'COMPLETED', true),
        filter(res => !!res && res.status === 'COMPLETED')
      )
      .subscribe(resultLink => {
        if (!!resultLink) {
          this.currentStatus = 'POLLING_ORDER_STATUS';
          this.currentStatusPercentage = 66;
          this.paymentLink = resultLink;
          this.adyenService
            .pollOrderStatus(this.paymentLink.reference)
            .pipe(
              catchError(() => of(undefined)),
              repeat({ delay: 3000 }),
              takeWhile(res => this.shouldContinuePolling && !res, true),
              filter(res => !!res)
            )
            .subscribe(res => {
              if (!!res) {
                this.isPolling = false;
                this.currentStatus = 'COMPLETED';
                this.currentStatusPercentage = 100;

                if (res.orderStatus === AdyenOrderStatus.ORDER_CREATION_FAILED) {
                  this.errorMessage = 'ADYEN_PAID_BUT_ORDER_CREATION_FAILED';
                  setTimeout(() => {
                    this.onPaymentCompleted.emit({
                      data: { resultCode: 'Authorised', sessionData: undefined, sessionResult: undefined },
                      pspReference: undefined,
                      merchantReference: this.paymentLink.reference,
                      selectedPaymentMethod: undefined,
                      element: res
                    });
                  }, 500);
                } else {
                  setTimeout(() => {
                    this.onPaymentCompleted.emit({
                      data: { resultCode: 'Authorised', sessionData: undefined, sessionResult: undefined },
                      pspReference: undefined,
                      merchantReference: this.paymentLink.reference,
                      selectedPaymentMethod: undefined,
                      element: res
                    });
                  }, 500);
                }
              }
            });
        }
      });
  }

  public cancelPaymentLink() {
    this.errorMessage = '';
    this.adyenService
      .cancelPaymentLink(this.paymentLink.id)
      .pipe(first())
      .subscribe({
        next: res => {
          this.isPolling = false;
          this.shouldContinuePolling = false;
          this.currentStatus = 'LINK_CANCELLED';
          this.currentStatusPercentage = 100;
          if (!!res) {
            this.paymentLink = res;
          }
        }
      });
  }
}

export interface AdyenPaymentCompletedEvent {
  data: PaymentCompletedData;
  pspReference: string;
  merchantReference: string;
  selectedPaymentMethod: string;
  element?: any;
}

export interface AdyenPaymentFailedEvent {
  data: PaymentCompletedData;
  merchantReference: string;
}
